import React, { useEffect, useRef, useState } from "react";
import { EN } from "../../../Languages/EN";
import { DE } from "../../../Languages/DE";
import LanguageContext from "../../../helper/LanguageContext";
import { $crud } from "../../../helper/CrudFactory";
import { Form } from "react-bootstrap";
import { FaTimes } from "react-icons/all";
import { KJUUP_COLORS } from "../../../helper/constants";

export default function FloatingAction({
  handleClose,
  showLocationDialog,
  showContactPage,
  sendImageMessage,
  sendVideoMessage,
  sendDocFile,
  setParam,
  chat,
  dropfiles,
  isInternal,
  messagePaar,
  userTyping,
  onBlur
}) {
  const imageInputRef = useRef(null);
  const { isGerm } = React.useContext(LanguageContext);
  const [image, setImage] = useState("");
  const [imageDimension, setImageDimension] = useState({ height: 0, width: 0 });
  const [video, setVideo] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    dropfiles?.forEach((file, index) => {
      if (file) {
        getHeightWidth(file, index);
        const fileType = file.name?.split(".").pop();
        const allowTypes = ["jpg", "png", "jpeg", "JPG", "PNG", "JGEG", 'heic', 'HEIC'];
        const videoAllowTypes = ["mpg", "mov", "mp4"];
        const docAllowTypes = [
          "doc",
          "docx",
          "txt",
          "pdf",
          "odt",
          "xls",
          "xlsx",
          "xlsb", "odt", "xlsm", 'ppt', 'dwg', 'odf', 'ifc', 'dwg', 'dxf', 'xlsm', 'pptx', 'csv', 'txt', 'mpp'
        ];
        const validImage = allowTypes.filter((val) => val.includes(fileType.toLowerCase()));
        const validVideo = videoAllowTypes.filter((val) =>
          val.includes(fileType.toLowerCase())
        );
        const validDoc = docAllowTypes.filter((val) => val.includes(fileType.toLowerCase()));

        if (validImage.length > 0) {
          setImage((prevValue) => [...prevValue, file]);
        } else if (validVideo.length > 0) {
          setVideo(file);
        } else if (validDoc.length > 0) {
          sendDocFile(file, file.name, chat.id);
          handleClose();
        }
      }
    });
  }, [dropfiles]);

  const handleUploadImageDialog = (e) => {
    // const file = await $crud.chooseFile();
    const { files } = e.target;
    let imageFiles = [];
    let videoFiles = [];
    if (files.length > 0) {
      Object.keys(files).forEach((key) => {
        const file = files[key];
        getHeightWidth(file, key);
        const fileType = file.name?.split(".").pop();
        const allowTypes = ["jpg", "png", "jpeg", "JPG", "PNG", "JGEG", 'heic', 'HEIC'];
        const videoAllowTypes = ["mpg", "mov", "mp4", 'MOV'];
        const validImage = allowTypes.filter((val) => val.includes(fileType.toLowerCase()));
        const validVideo = videoAllowTypes.filter((val) =>
          val.includes(fileType.toLowerCase())
        );

        if (validVideo.length > 0 && videoFiles.length > 0) {
          setVideo("");
          $crud.notify({
            type: "error",
            message: "Please select one video file at a time.",
          });
          return;
        } else if (
          (imageFiles.length > 0 || validImage.length > 0) &&
          (videoFiles.length > 0 || validVideo.length > 0)
        ) {
          setVideo("");
          $crud.notify({
            type: "error",
            message: "Please select multiple image or single video file only.",
          });
          return;
        } else if (validVideo.length > 0) {
          videoFiles = [...videoFiles, file.name];
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = (e) => {
            file.url = fileReader.result;
            setVideo(file);
          };
        } else if (validImage.length > 0) {
          imageFiles = [...imageFiles, file.name];
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = (e) => {
            file.url = fileReader.result;
            setImage((prevValue) => [...prevValue, file]);
          };
        } else {
          $crud.notify({
            type: "error",
            message: "Upload image in  ['jpg','png','jpeg']",
          });
        }
      });
    }
  };

  function getHeightWidth(file, index) {
    var reader = new FileReader();

    //Read the contents of Image File.
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result;

      //Validate the File Height and Width.
      image.onload = function () {
        const height = this.height;
        const width = this.width;
        setImageDimension((prevValue) => {
          const tempObj = {
            ...prevValue,
            [index]: { width: width, height: height },
          };
          return tempObj;
        });
        return true;
      };
    };
  }

  const handleUploadDoc = async (chatId) => {
    const file = await $crud.chooseFile();
    const fileType = file.name?.split(".").pop();
    const allowTypes = ["doc", "docx", "pdf", "odt", "xls", "xlsx", "xlsb", "odt", "xlsm", 'ppt', 'dwg', 'odf', 'ifc', 'dwg', 'dxf', 'xlsm', 'pptx', 'csv', 'txt', 'mpp'];
    const validFile = allowTypes.filter((val) => val.includes(fileType.toLowerCase()));
    if (validFile.length > 0) {
      sendDocFile(file, file.name, chatId);
      handleClose();
    } else {
      $crud.notify({
        type: "error",
        message: "Upload file in  ['doc','docx'] format",
      });
    }
  };
  const sendMessage = (chatId) => {
    sendImageMessage(messagePaar, image, imageDimension, chatId);
    handleClose();
  };
  const handleVideoMessage = (chatId) => {
    sendVideoMessage(video, message, chatId);
    handleClose();
  };

  // const userTyping = async (e) => {
  //   setMessage(e.currentTarget.textContent);
  //   const id = chat.id;
  // };
  // const setText = (e) => {
  //   const htmlData = e.target.innerHTML;
  //   const plainText = htmlData.replace(/<\/?[^>]+>/gi, " ");
  //   e.target.innerHTML = plainText;
  // };

  const onInputClick = (event) => {
    event.target.value = "";
  };

  return (
    <div className="backdrop">
      {!image.length && !video && (
        <div className="role_popup dialog shadow-sm click_plus">
          <div className="role_data">
            <ul className="list-group">
              <li
                className={isInternal ? "li-internal" : "pointer"}
                onClick={() => imageInputRef.current.click()}
              >
                <input
                  type="file"
                  hidden
                  multiple
                  onChange={handleUploadImageDialog}
                  onClick={onInputClick}
                  accept={["image/*", '.heic', '.HEIC']}
                  ref={imageInputRef}
                />
                <span>
                  <img
                    width="25px"
                    src={process.env.PUBLIC_URL + "/assets/img/add-media.svg"}
                    alt="add-media"
                  />
                </span>{" "}
                {isGerm ? DE.photo_video_media_lib : EN.photo_video_media_lib}
              </li>
              <li className={isInternal ? "li-internal" : "pointer"} onClick={() => handleUploadDoc(chat.id)}>
                <span>
                  <img
                    width="18px"
                    src={process.env.PUBLIC_URL + "/assets/img/Documents.svg"}
                  />
                </span>{" "}
                {isGerm ? DE.document : EN.document}
              </li>
              {/* <li className={isInternal ? "li-internal" : "pointer"} onClick={showLocationDialog}>
                <span>
                  <img
                    width="15px"
                    src={process.env.PUBLIC_URL + "/assets/img/Location.svg"}
                  />
                </span>{" "}
                {isGerm ? DE.location_meeting : EN.location_meeting}
              </li> */}
              <li className={isInternal ? "li-internal" : "pointer"} onClick={showContactPage}>
                <span>
                  <img
                    width="25px"
                    src={process.env.PUBLIC_URL + "/assets/img/contact-us.svg"}
                  />
                </span>{" "}
                {isGerm ? DE.contact : EN.contact}
              </li>
            </ul>
          </div>
          <div className="bottom-button">
            <a className={isInternal ? "li-internal" : "pointer"} style={{ color: isInternal ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color }} onClick={handleClose}>
              {isGerm ? DE.cancel : EN.cancel}
            </a>
          </div>
        </div>
      )}
      {image.length > 0 && (
        <div
          className={
            image.length > 0
              ? "role_popup customize-floating-dialog shadow-sm click_plus"
              : "role_popup shadow-sm click_plus"
          }
          style={{height: 'auto'}}
        >
          <div className="text-center p-2 m-0 mb-2">
            <div className="row m-0">
              <div className="col-md-12 p-0 pb-2">
                <div
                  className="embed-responsive embed-responsive-16by9"
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    overflow: image.length > 2 ? "auto" : "",
                    maxHeight: image.length > 2 ? "240px" : "",
                    marginTop: 30,
                  }}
                >
                  {image?.map((each) => (
                    (!each.type || (each.type === 'image/heic' || each.name.endsWith('.heic'))) ?
                      <img
                        className={
                          image.length >= 2
                            ? "upload-multi-group-image"
                            : "upload-group-image"
                        }
                        src={process.env.PUBLIC_URL + (`assets/img/${isGerm ? 'no-image-preview-de.png' : 'no-image-preview-en.png'}`)}
                        // src={process.env.PUBLIC_URL + "assets/img/no-image-preview-de.png"}
                        key={each?.name}
                        alt={each?.name}
                      />
                      :
                      <img
                        className={
                          image.length >= 2
                            ? "upload-multi-group-image"
                            : "upload-group-image"
                        }
                        src={each.url}
                        key={each?.name}
                        alt={each?.name}
                      />
                  ))}
                </div>
              </div>
              <div className="col-md-12">
                <div className="row align-items-center">
                  <div className="col-md-11 pl-2 pr-2">
                    <div className="input_text_msg">
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-0"
                      >
                        <textarea
                          role="textarea"
                          contentEditable="true"
                          className={`customInputBox`}
                          onInput={(e) => userTyping(e)}
                          onBlur={(e) => onBlur(e)}
                          value={messagePaar}
                          style={{ height:0, minHeight: 34, maxHeight: "45vh", padding: 0, paddingLeft: '0.25rem', paddingTop: "0.25rem", paddingBottom:0 }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="col-md-1 pl-1 pr-1 click_send">
                    <a href="#" onClick={() => sendMessage(chat.id)}>
                      <img
                        className="p-0"
                        width={20}
                        src={process.env.PUBLIC_URL + (!isInternal ? "/assets/img/Send.svg" : "/assets/img/SendGreen.svg")}
                        alt="Generic placeholder"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <a className="pointer top-close" onClick={handleClose}>
            <FaTimes fill={isInternal ? KJUUP_COLORS.internal : KJUUP_COLORS.base_color} size={20} />
          </a>
        </div>
      )}
      {video && (
        <div
          className={
            video
              ? "role_popup customize-floating-dialog shadow-sm click_plus"
              : "role_popup shadow-sm click_plus"
          }
        >
          <div className="role_data text-center p-2 m-0">
            <div className="row m-0">
              <div className="col-12 p-2">
                <div className="embed-responsive embed-responsive-16by9">
                  <iframe
                    className="embed-responsive-item"
                    src={video.url}
                    allowFullScreen
                  />
                </div>
              </div>
              <div className="col-11 p-2">
                <div className="input_text_msg">
                  <Form.Group
                    controlId="exampleForm.ControlInput1"
                    className="mb-0"
                  >
                    <textarea
                      role="textarea"
                      contentEditable="true"
                      className={`customInputBox`}
                      onInput={(e) => userTyping(e)}
                      onBlur={(e) => onBlur(e)}
                      value={messagePaar}
                      style={{ height: 34 }}
                    />
                  </Form.Group>
                </div>
              </div>
              <div className="col-1 p-2 click_send">
                <a href="#" onClick={() => handleVideoMessage(chat.id)}>
                  <img
                    width={20}
                    src={process.env.PUBLIC_URL + "/assets/img/Send.svg"}
                    alt="Generic placeholder"
                  />
                </a>
              </div>
            </div>
          </div>
          <a className="pointer top-close" onClick={handleClose}>
            <FaTimes size={20} />
          </a>
        </div>
      )}
    </div>
  );
}
