import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory, withRouter } from "react-router-dom";
import LanguageContext from "../../../helper/LanguageContext";
import ShareProjectMapDialog from "../../../components/ProjectMapShare/ShareProjectMapDialog";
import { callAPI } from "../../../helper/apiUtils";
import { $crud } from "../../../helper/CrudFactory";
import CompanyListDialog from "../Dialogs/CompanyListDialog";
import {
  BASE_URL,
  KJUUP_COLORS,
  TOGGLE_DRAWER,
} from "../../../helper/constants";
import _ from "lodash";
import {
  FORWARD_GROUP_MESSAGES,
  GROUP_CHATS,
  OPEN_PROJECTS,
  PROJECTS,
  UPDATE_PROJECT_ACTION_FIRE,
  DRAFT_MESSAGES,
  IS_OPEN_BY_OWNER,
  ALL_BADGE_COUNT,
  SET_ALLGROUPS_PROJECTS,
  INTERNAL_PROJECTS,
  OPEN_BOTTOM_PROJECTS,
  SET_ACTIVITY_MESSAGES,
  SET_ACTIVITY_MESSAGES_REMINDERS,
} from "../../../store";
import { connect, useDispatch, useSelector } from "react-redux";
import EditProject from "../EditProject";
import AddProjectUser from "../AddProjectUser";
import { Scrollbars } from "react-custom-scrollbars";
import { DE } from "../../../Languages/DE";
import { EN } from "../../../Languages/EN";
import * as moment from "moment";
import "../ProjectChat.css";
import AddProjectChatUser from "../AddProjectChatUser";
import GroupConversation from "../GroupChat/GroupConversation";
import { $user } from "../../../helper/UserFactory";
import BuildingMediaDocs from "../BuildingMediaDocs";
import $ from "jquery";
import {
  convertBranchRole,
  convertCapitalLeter,
} from "../../../helper/convertor";
import Geocode from "react-geocode";
import ProjectSettings from "../ProjectSettings";
import { onlyUsername } from "../GroupChat/getUsername";
import { BsFillPlusCircleFill, BsSearch } from "react-icons/bs";
import ProjectMembers from "../ProjectMembers";
Geocode.setApiKey("AIzaSyBY4CL97jLZQR77C7mvJCWM5Wq2HkYk69Q");
Geocode.setLocationType("ROOFTOP");
const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
    height: "calc(44vh - 163px)",
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

function ProjectDetailSection(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = $user.get();
  const _getChatCallBAck = useCallback(() => getChats(), []);
  const _openChatCallBack = useCallback((id) => handleCloseChat(id), []);
  const _addMemberCallBack = useCallback(() => {
    setAddUserAction({ status: true, id: id });
    setAddChatUserAction({ status: false, id: null });
  }, []);
  const _leaveProjectCallBack = useCallback((id) => handleLeaveProject(id), []);

  const detailDiv = useRef(HTMLDivElement);
  const infWin = useRef(HTMLDivElement);
  const [visible, setVisible] = useState(true);
  const [calcVar, setCalcVar] = useState(0);
  const [animateHide, setAnimateHide] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [gpId, setDpId] = useState(null);
  const [params, setParams] = useState({
    companyId: "",
    id: "",
    companySelect: false,
  });
  const activityMessages = useSelector((e) => e.activityMessages);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [activeForward, setActiveForward] = useState(false);
  const [selectedGroupData, setSelectedGroupData] = useState([]);
  const [toggleInfo, setToggleInfo] = useState(false);
  const [allGrps, setGrps] = useState(props.groupChats);
  const { projects } = useSelector((state) => state);
  const { internalProjectsAll } = useSelector((state) => state);
  const activityMessagesReminders = useSelector(
    (e) => e.activityMessagesReminders
  );
  let openGroups = useSelector((e) => e.groupChats);
  const [isKjuup, setIsKjuup] = useState(false);
  // console.log('---->open groups', openGroups)
  useEffect(() => {
    setGrps(props.groupChats);
  }, [props.groupChats]);

  useEffect(() => {
    setIsKjuup(props.project.isInternal);
  },[]);

  // useEffect(()=>{
  //   if(props.project.group){
  //     console.log(props.project.group)
  //   }
  // },[props.project.id])
  const [edit, setEdit] = useState({
    status: false,
    id: null,
    isInternal: props.project.isInternal,
  });
  const [addUserAction, setAddUserAction] = useState({
    status: !!props.addUserAction.openAddProjectWindow,
    id: props.addUserAction.id,
    isInternal: props.project.isInternal,
    cID: props.project.cID,
    addAsMember: props.project.addAsMember,
  });
  const [addChatUserAction, setAddChatUserAction] = useState({
    status: false,
    id: props.addUserAction.id,
    isInternal: props.project.isInternal,
    cID: props.project.cID,
  });
  const [viewLoader, setViewLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [showCompanyDialog, setCompanyDialog] = useState(false);
  const [showMember, setShowMember] = useState(false);
  const [projectDocAction, setProjectDocAction] = useState(false);
  const [projectDetails, setProjectDetails] = useState(props.project);
  const [isWidthBox, setWidthBox] = useState(0);
  const [allUsers, setAllUsers] = useState([]);
  const [openSetting, setOpenSetting] = useState(false);
  const [storedDraftMessage, setStoredDraftMessage] = useState([]);
  const AllGroupsOfProjects = useSelector((state) => state.AllGroupsOfProjects);
  const [alreadyExist, setAlreadyExist] = useState([]);
  const [search, setSearch] = useState("");
  const handleShow = async (project) => {
    setShow(true);
    setProjectDetails(project);
  };
  // console.log(projectDetails)
  const handleClose = () => setShow(false);
  const [users, setUsers] = useState([]);
  const [usersBackUp, setUsersBackUp] = useState([]);
  const [searchUser, setSearchUser] = useState("");
  const [members, setMembers] = useState([]);
  const { isGerm } = React.useContext(LanguageContext);
  const id = props.project.id;
  const { unSeenAllCount } = useSelector((state) => state);
  const getProjectDetails = async () => {
    const { data } = await callAPI(
      `/building/one/${id}`,
      {
        id: id,
      },
      "GET"
    );
    if (data.status) {
      const updatedData = { ...data, viewByOwner: !!props.project.viewByOwner };
      await setProjectDetails(updatedData);
    } else {
      if (props.project.viewByOwner) {
        const projectDetailsUpdate = { ...projectDetails, isJoin: false };
        await setProjectDetails(projectDetailsUpdate);
      }
    }
    setViewLoader(false);
  };
  useEffect(() => {
    getProjectDetails();
  }, [props.id]);
  const [companies, setCompanies] = useState([]);

  const getCompanies = async () => {
    const { data } = await callAPI(`/company/my`, {}, "GET");
    if (data.status) {
      setCompanies(data.companies);
    }
  };

  useEffect(() => {
    const localStoredMessage = JSON.parse(localStorage.getItem("draftMessage"))
      ? JSON.parse(localStorage.getItem("draftMessage"))
      : [];
    setStoredDraftMessage(localStoredMessage);
    props.updateDraftMessages(localStoredMessage);

    getCompanies();
    getMembers();
    if (toggleInfo) {
      const body = document.querySelector("body");
      const cv = body.clientHeight / 2 - infWin.current.clientHeight - 110;
      setCalcVar(cv);
    }
  }, []);

  useEffect(() => {
    const body = document.querySelector("body");
    if (!toggleInfo) {
      const cv = body.clientHeight / 2 - 110;
      setCalcVar(cv);
    } else {
      const cv = body.clientHeight / 2 - infWin.current.clientHeight - 110;
      setCalcVar(cv);
    }
  }, [toggleInfo]);

  useEffect(() => {
    if (props.updateProjectActionFire) {
      getProjectDetails();
      props.updateProjectAction(false);
    }
  }, [props.updateProjectActionFire]);

  const getProjectName = (name) => {
    if (name) {
      const nameArray = name?.split(" ");
      if (nameArray && nameArray.length) {
        const length = nameArray.length;
        let newName = [];
        newName.push(nameArray[0][0]);
        if (nameArray.length > 1) {
          newName.push(nameArray[length - 1][0]);
        }
        return newName.join("");
      }
    }
  };

  const leaveProject = async (id) => {
    await $crud.confirm({
      title: "",
      textContent: isGerm
        ? "Wollen Sie den Raum wirklich verlassen? Wenn Sie den Raum verlassen, verlassen Sie auch alle damit verbundenen Chat Gruppen."
        : "Do you really want to leave the room? When leaving the room you will also leave all associated chat groups.",
      options: {
        ok: isGerm ? DE.confirm : EN.confirm,
        cancel: isGerm ? DE.cancel : EN.cancel,
      },
    });
    const { data } = await callAPI(
      `/building/${id}/leave`,
      {
        id: id,
      },
      "GET"
    );
    if (data.status) {
      $crud.notify({
        type: data.status ? "success" : "error",
        message: data.message,
      });
    }
    const updateProjectList = props.openProjects.filter(
      (project) => project.id !== id
    );
    props.updateOpenProjectsList(updateProjectList);
    props.updateProjectAction(true);
    setOpenSetting(false);
  };
  const setParam = async (name, value) => {
    setParams((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  //Handle Re-Join project
  const handleJoin = async (project) => {
    await $crud.confirm({
      title: "",
      textContent: isGerm
        ? `Wollen Sie dem Raum ${project.name} wirklich wieder beitreten?`
        : `Do you really want to re-join the room ${project.name}?`,
      options: {
        ok: "Confirm",
        cancel: "Cancel",
      },
    });
    if (companies.length > 1) {
      await setParam("id", project.id);
      setCompanyDialog(true);
    } else if (companies.length === 1) {
      await submitReJoinSingleCompany(project.id, companies[0].id);
    } else {
      await submitReJoinWithoutCompany(project.id);
    }
  };

  const submitReJoinSingleCompany = async (project_id, companyId) => {
    const { data } = await callAPI(
      `/building/${params.id}/join`,
      {
        id: project_id,
        companyId: companyId,
      },
      "PUT"
    );
    if (data.status) {
      $crud.notify({
        type: data.status,
        message: data.message,
      });
      getProjectDetails();
      props.updateProjectAction(true);
    }
  };

  //Submit RE-join request
  const submitReJoinCompany = async (company) => {
    const { data } = await callAPI(
      `/building/${params.id}/join`,
      {
        id: params.id,
        companyId: company.id,
      },
      "PUT"
    );
    if (data.status) {
      $crud.notify({
        type: data.status,
        message: data.message,
      });
      getProjectDetails();
      props.updateProjectAction(true);
    }
  };
  //Submit RE-join request without company
  const submitReJoinWithoutCompany = async (id) => {
    const { data } = await callAPI(`/building/${id}/join`, {}, "PUT");
    if (data.status) {
      $crud.notify({
        type: data.status,
        message: data.message,
      });
      getProjectDetails();
      props.updateProjectAction(true);
    }
  };

  const getMembers = async () => {
    const { data } = await callAPI(`/building/${id}/user`, {}, "GET");
    if (data.status) {
      let notAccepted = data?.requestNotYetAccepted?.map((item) => {
        return { ...item, requestNotYetAccepted: true, isLeaved: true };
      });
      let _m = [...data.members];
      setMembers([..._m, ...notAccepted]);
      await CreateList([..._m, ...notAccepted]);
    }
  };

  const CreateList = async (users) => {
    const arr = await _.uniqBy(
      users.map((user) => {
        let name = { name: user.lastName.trim()[0].toUpperCase() };
        return name;
      }),
      "name"
    );
    const updatedArr = _.sortBy(arr, ["name"]);
    const usersList = await updatedArr.map((a) => {
      let names = users.filter(
        (u) => u.lastName.trim()[0].toUpperCase() === a.name
      );
      a.names = names;
      return a;
    });
    setUsers(usersList);
    setUsersBackUp(usersList);
  };

  useEffect(() => {
    if (allUsers?.length > 0) {
      let filterData = allUsers?.filter(
        (elem) => !members?.find(({ id }) => elem.id === id)
      );
      let allData = [...filterData, ...members];
      const arr = _.uniqBy(
        allData?.map((us) => {
          let name = { name: us?.lastName.trim()[0]?.toUpperCase() };
          return name;
        }),
        "name"
      );
      const updatedArr = _.sortBy(arr, ["name"]);
      const usersList = updatedArr?.map((a) => {
        let names = allData.filter(
          (u) => u.lastName.trim()[0].toUpperCase() === a.name
        );
        a.names = names;
        return a;
      });
      setUsers(usersList);
      setUsersBackUp(usersList);
    }
  }, [allUsers]);

  const hideProject = async () => {
    let tmp = [...activityMessages];
    tmp = tmp.map((e) => {
      return {
        ...e,
        isOpened: e.buildingList == props.id ? false : e.isOpened,
      };
    });
    let tmp1 = [...activityMessagesReminders];
    tmp1 = tmp1.map((e) => {
      return {
        ...e,
        isOpened: e.buildingList == props.id ? false : e.isOpened,
      };
    });
    dispatch({ type: SET_ACTIVITY_MESSAGES, messages: tmp });
    dispatch({ type: SET_ACTIVITY_MESSAGES_REMINDERS, messages: tmp1 });
    const { openProjects, id } = props;
    // setDeleteId(id);
    if (props.isOpenByOwner && openProjects.length == 1) {
      props.isOwnerOpenProject(false);
    }
    const oldData = await openProjects.filter((p) => p.id !== id);
    const updateProjects = props.projects.map((g) => {
      if (g.id === id) {
        return { ...g, isOpened: false };
      } else {
        return { ...g };
      }
    });
    props.updateProjects(updateProjects);
    props.updateOpenProjectsList(oldData);
  };

  const handleEdit = (id) => {
    setEdit({
      status: true,
      id: id,
      isInternal: props.project.isInternal,
    });
  };

  const [chatGroups, setChatGroups] = useState([]);
  const [disableGroup, setDisableGroup] = useState([]);

  useEffect(() => {
    const isOpened = chatGroups.filter((e) => e.isOpened);
    setWidthBox(isOpened.length ? isOpened.length * 400 + 440 : 440);
  }, [chatGroups]);

  const getChats = async () => {
    const { data } = await callAPI(
      `/building/${projectDetails?.id}/chat`,
      {
        viewByOwner: props.project.viewByOwner,
        userId: props.project.ownerId ? props.project.ownerId : user?.id,
        lang: isGerm ? "de" : "en",
      },
      "GET"
    );
    if (data.status) {
      let chatsData = await Promise.all(
        data.groups.map(async (chat) => {
          return {
            ...chat,
            isOpened: false,
            // isLeave: await checkExist(chat.users),
          };
        })
      );

      let chatGroupUsers = [];
      if (props.project.viewByOwner) {
        chatGroupUsers = chatsData.map((group) => {
          if (
            group.isLeave === true &&
            group.users.find(
              (u) => u.id === (props.project.ownerId ?? user?.id)
            )
          ) {
            group.disabled = true;
          } else {
            group.disabled = false;
          }
          return group;
        });
      } else {
        chatGroupUsers = chatsData.map((group) => {
          if (
            group.isLeave === true &&
            group.users.find(
              (u) => u.id === (props.project.ownerId ?? user?.id)
            )
          ) {
            return {
              ...group,
              isOpened: !!props.groupChats.find((g) => g.id === group.id),
              disabled: true,
            };
          } else {
            return {
              ...group,
              isOpened: !!props.groupChats.find((g) => g.id === group.id),
              disabled: false,
            };
          }
        });
      }
      let userData = JSON.parse(localStorage.getItem("user"));
      let filterData = chatGroupUsers
        .sort((a, b) => (a.isLeave === true) - (b.isLeave === true))
        .filter((item) => {
          if (!props.project.viewByOwner)
            return item.users.find((it) => it.id === userData.id) && item;
          else
            return (
              item.users.find((it) => it.id === props.project.ownerId) && item
            );
        });
      dispatch({
        type: SET_ALLGROUPS_PROJECTS,
        payload: {
          projectId: projectDetails?.id,
          newGroups: _sortArray([...filterData]),
        },
      });
    }
  };

  const _sortArray = (res) => {
    let _temp = [...res];
    let _joined = [];
    let _leaved = [];
    for (let obj of _temp) {
      let chatUser = obj.users.filter((u) => u.id == user.id);
      if (chatUser?.length > 0) {
        if (!chatUser[0]?.isLeave) {
          _joined.push(obj);
        } else {
          _leaved.push(obj);
        }
      } else {
        _leaved.push(obj);
      }
    }
    return _joined
      .sort((a, b) => {
        return b.defaultChatGroup - a.defaultChatGroup;
      })
      .concat(_leaved);
  };

  useEffect(() => {
    let ind = AllGroupsOfProjects.findIndex(
      (e) => e.projectId == projectDetails?.id
    );
    if (ind !== -1) {
      setDisableGroup(AllGroupsOfProjects[ind].groups);
      setChatGroups(AllGroupsOfProjects[ind].groups);
    }
  }, [AllGroupsOfProjects, projectDetails]);
  const checkExist = async (users) => {
    const getUser = users.find((u) => u.id === user?.id && u.isLeave);
    return !!getUser;
  };

  useEffect(() => {
    getChats();
  }, [projectDetails?.id, addChatUserAction]);

  useEffect(() => {
    if (props.updateGroupMessage?.message === "") {
      getChats();
    } else {
      let groupsData = chatGroups.map((g) => {
        if (g.id === props.updateGroupMessage.group) {
          g.lastMessage = props.updateGroupMessage.message;
          g.originalMessage = props.updateGroupMessage.message;
          g.lastMessageType = props.updateGroupMessage.messageType;
          g.lastMessageDeleted = props.updateGroupMessage.lastMessageDeleted;
        }
        return g;
      });
      Promise.resolve().then(() => {
        setChatGroups(groupsData);
      });
    }
  }, [props.updateGroupMessage]);

  useEffect(() => {
    setStoredDraftMessage(props.draftMessages);
  }, [props.draftMessages]);

  const setDraftMessage = (message, group) => {
    message = message.trim();
    if (message) {
      const msgObject = {
        userId: user?.id,
        message: message,
        messageType: "simple",
        group: group.id,
      };

      let allMessages = props.draftMessages;
      const foundIndex = allMessages.findIndex(
        (msg) => msg.userId === user?.id && msg.group === group.id
      );
      if (foundIndex > -1) {
        allMessages[foundIndex] = {
          ...allMessages[foundIndex],
          message: message,
        };
      } else {
        allMessages = [...allMessages, msgObject];
      }
      localStorage.setItem("draftMessage", JSON.stringify(allMessages));
      props.updateDraftMessages(allMessages);
    }
  };

  const openChat = async (group) => {
    let tmpZ = { ...group };
    const chk = props.groupChats.find((g) => g.id === group.id);
    if (chk) {
      setDraftMessage($(`.textInput-${group.id}`).text(), group);
      const openGroupChats = props.groupChats.filter((g) => g.id !== group.id);
      const updateChatGroups = await chatGroups.map((p) => {
        if (p.id === group.id) {
          return { ...p, isOpened: false };
        } else {
          return { ...p };
        }
      });
      let tmp = [...activityMessages];
      tmp = tmp.map((e) => {
        return {
          ...e,
          isOpened:
            e.buildingList == props.id && e.group.id == group.id
              ? false
              : e.isOpened,
        };
      });
      let tmp1 = [...activityMessagesReminders];
      tmp1 = tmp1.map((e) => {
        return {
          ...e,
          isOpened:
            e.buildingList == props.id && e.group.id == group.id
              ? false
              : e.isOpened,
        };
      });
      dispatch({ type: SET_ACTIVITY_MESSAGES, messages: tmp });
      dispatch({ type: SET_ACTIVITY_MESSAGES_REMINDERS, messages: tmp1 });
      setChatGroups(updateChatGroups);
      let x = [...openGroupChats];
      props.updateGroupChats(x);
    } else {
      let tmpx = [...activityMessages];
      tmpx = tmpx.map((e) => {
        return {
          ...e,
          isOpened:
            e.buildingList == props.id && e.group.id == group.id
              ? true
              : e.isOpened,
        };
      });
      let tmp1 = [...activityMessagesReminders];
      tmp1 = tmp1.map((e) => {
        return {
          ...e,
          isOpened:
            e.buildingList == props.id && e.group.id == group.id
              ? false
              : e.isOpened,
        };
      });
      dispatch({ type: SET_ACTIVITY_MESSAGES, messages: tmpx });
      dispatch({ type: SET_ACTIVITY_MESSAGES_REMINDERS, messages: tmp1 });
      setDraftMessage($(`.textInput-${group.id}`).text(), group);
      props.updateGroupChats([...props.groupChats, group]);
      const updateChatGroups = await chatGroups.map((p) => {
        if (p.id === group.id) {
          return { ...p, isOpened: true };
        } else {
          return { ...p };
        }
      });
      setChatGroups(updateChatGroups);
      let isInternal = -1;
      let isExternal = projects.findIndex((e) => e.id == group.building);
      let TXX = [...internalProjectsAll];
      for (let obj of TXX) {
        if (obj.userProjectList && obj.userProjectList.length > 0) {
          let ind = obj.userProjectList.findIndex(
            (e) => e.id == group.building
          );
          if (ind !== -1) {
            isInternal = 1;
            obj.totalUnreadChat =
              obj.totalUnreadChat - group.unseenChatCount < 0
                ? 0
                : obj.totalUnreadChat - group.unseenChatCount;
            obj.userProjectList[ind].unseenBuildingChat =
              obj.userProjectList[ind].unseenBuildingChat -
                group.unseenChatCount <
                0
                ? 0
                : obj.userProjectList[ind].unseenBuildingChat -
                group.unseenChatCount;
          }
        }
      }
      if (isExternal !== -1) {
        let TXXEXTERNAL = [...projects];
        TXXEXTERNAL[isExternal].unseenBuildingChat =
          TXXEXTERNAL[isExternal].unseenBuildingChat - group.unseenChatCount < 0
            ? 0
            : TXXEXTERNAL[isExternal].unseenBuildingChat -
            group.unseenChatCount;
        dispatch({ type: PROJECTS, projects: TXXEXTERNAL });
      }
      dispatch({ type: INTERNAL_PROJECTS, projects: TXX });
      let a = { ...unSeenAllCount };
      let unseenInternalProject =
        isInternal !== -1
          ? a.unseenInternalProject - tmpZ.unseenChatCount
          : a.unseenInternalProject;
      let unseenExternalProject =
        isExternal !== -1
          ? a.unseenExternalProject - tmpZ.unseenChatCount
          : a.unseenExternalProject;
      let f = {
        ...a,
        unseenBuildingChatCount:
          a.unseenBuildingChatCount - tmpZ.unseenChatCount < 0
            ? 0
            : a.unseenBuildingChatCount - tmpZ.unseenChatCount,
        unseenExternalProject,
        unseenInternalProject,
      };
      props.updateAllBadgeCount(f);
      let tmp = [...AllGroupsOfProjects];
      let allGrps = tmp.findIndex((e) => e.projectId == group.building);
      if (allGrps !== -1) {
        let currentGroup = [...tmp[allGrps].groups];
        let gIndex = currentGroup.findIndex((e) => e.id == group.id);
        if (gIndex !== -1) {
          tmp[allGrps].groups[gIndex].unseenChatCount = 0;
          dispatch({
            type: SET_ALLGROUPS_PROJECTS,
            payload: {
              projectId: group.building,
              newGroups: tmp[allGrps].groups,
            },
          });
        }
      }
    }
  };

  const handleCloseChat = async (id, isLeave = false) => {
    const updateChatGroups = await chatGroups.map((p) => {
      if (p.id === id) {
        if (isLeave) {
          return { ...p, isOpened: false, isLeave: true, disabled: true };
        } else {
          return { ...p, isOpened: false };
        }
      } else {
        return { ...p };
      }
    });
    let sortData = updateChatGroups?.sort(
      (a, b) => (a.isLeave === true) - (b.isLeave === true)
    );
    if (isLeave) {
      setChatGroups(updateChatGroups);
    } else {
      setChatGroups(sortData);
    }
  };

  const handleForward = async () => {
    const countGroups = selectedGroups.length;
    if (countGroups > 0) {
      setViewLoader(true);
      const { data } = await callAPI("/message/forward", {}, "POST", {
        group: selectedGroups,
        id: props.forwardGroupMessages.msgIds,
      });
      if (data.status) {
        $crud.notify({
          type: "success",
          message: "Message forwarded!",
        });
      }
      props.updateForwardGroupMessage({});
      props.updateForwardGroupMessage([]);
      await selectedGroupData?.map((itm) => openChat(itm));
      await setActiveForward(false);
      await setSelectedGroups([]);
      await setSelectedGroupData([]);
      setViewLoader(false);
    } else {
      $crud.notify({
        type: "error",
        message: "Please select group to forward message",
      });
    }
  };
  const handleCancel = () => {
    props.updateForwardGroupMessage([]);
    setActiveForward(false);
  };

  const selectIds = (id, group) => {
    const chk = selectedGroups.find((g) => g === id);
    if (!chk) {
      const ids = [...selectedGroups, id];
      setSelectedGroups(ids);
      const data = [...selectedGroupData, group];
      setSelectedGroupData(data);
    } else {
      const ids = selectedGroups.filter((g) => g !== id);
      const datas = selectedGroupData.filter((g) => g?.id !== group?.id);
      setSelectedGroups(ids);
      setSelectedGroupData(datas);
    }
  };
  useEffect(() => {
    if (
      props.forwardGroupMessages &&
      props.forwardGroupMessages.building === id
    ) {
      setActiveForward(true);
      setDpId(props.forwardGroupMessages.gpId);
    }
  }, [props.forwardGroupMessages]);

  //Search Project
  const searchProject = async (e) => {
    const value = e.target.value.trim();
    setSearch(value);
    const searchResult = await chatGroups.filter((project) => {
      if (value === "") {
        return project;
      } else if (project.name.toLowerCase().includes(value.toLowerCase())) {
        return project;
      }
    });
    if (value === "") {
      setChatGroups(disableGroup);
    } else {
      setChatGroups(searchResult);
    }
  };
  const handleLeaveProject = async (projectId) => {
    let filterData = chatGroups?.map((it) =>
      it.id === projectId ? { ...it, isLeave: true, disabled: true } : { ...it }
    );
    let sortData = filterData.sort(
      (a, b) => (a.isLeave === true) - (b.isLeave === true)
    );
    setChatGroups(sortData);
    setDisableGroup(sortData);
    handleCloseChat(projectId, true);
    const oldData = await props.groupChats.filter((p) => p.id !== projectId);
    props.updateGroupChats(oldData);
  };

  const _deleteMemberFromProject = async (id, index, mainIndex) => {
    const { data } = await callAPI(
      `/building/${projectDetails.id}/remove/${id}`,
      null,
      "DELETE"
    );
    if (data.status) {
      let tmp = [...users];
      tmp[mainIndex].names.splice(index, 1);
      setUsers([...tmp]);
      $crud.notify({
        type: "success",
        message: data.message,
      });
    } else {
      $crud.notify({
        type: "error",
        message: data.message,
      });
    }
  };

  const navigateUserProfile = (userId) => {
    dispatch({ type: TOGGLE_DRAWER, open: true });
    history.push(`/user-profile/${userId}`);
  };


  const filterAdmins = async (searchValue) => {
    setSearchUser(searchValue);
    searchValue = searchValue.trim();
    if (searchValue) {
      let userListFilter = await usersBackUp.map((item) => {
        let filterName = item.names.filter((itm) => {
          let fullName =
            `${itm.firstName.trim()} ${itm.lastName.trim()}`.toLowerCase();
          if (
            itm.firstName.toLowerCase().startsWith(searchValue.toLowerCase()) ||
            itm.lastName.toLowerCase().startsWith(searchValue.toLowerCase()) ||
            fullName.toLowerCase().startsWith(searchValue.toLowerCase())
          ) {
            return true;
          }
        });
        return { ...item, names: filterName };
      });
      userListFilter = userListFilter.filter(
        (position) => position.names.length > 0
      );
      setUsers(userListFilter);
    } else {
      setUsers(usersBackUp);
    }
  };

  return (
    <React.Fragment>
      <div
        className={
          deleteId !== props.id ? "close-fix" : "close-fix close-right"
        }
      // style={{ maxWidth: isWidthBox + "px" }}
      >
        {viewLoader && (
          <div className="website_loader">
            <div className="loader" />
          </div>
        )}
        <div
          onAnimationEnd={() => {
            setDeleteId(null);
          }}
          className={
            animateHide
              ? "project-details handle-drag close-project-details"
              : "open-project-details project-details handle-drag position-relative"
          }
          style={{ paddingBottom: 0, marginBottom: 0, backgroundColor: "#fff" }}
        >
          {user && projectDetails && (
            <div
              ref={detailDiv}
              hide={animateHide}
              className={
                animateHide
                  ? "w-300 my_project_user r_cont kachada h45vh right_btm_ani-enter-done border-radius-0 project-details close-project-details"
                  : "w-300 my_project_user r_cont kachada h45vh right_btm_ani-enter-done border-radius-0 project-details"
              }
              style={{
                backgroundColor: "#fff",
                height: "93vh",
                display: "flex",
                flexDirection: "column",
                borderColor: props.project.isInternal
                  ? KJUUP_COLORS.internal
                  : KJUUP_COLORS.base_color,
              }}
            >
              <div
                className="user_close_info"
                style={{
                  backgroundColor:
                    projectDetails?.isJoin && !props?.isOpenByOwner
                      ? projectDetails?.themeColor
                      : "#d9deda",
                }}
              >
                <div
                  className="side_u_name"
                  style={{
                    backgroundColor:
                      projectDetails?.isJoin && !props?.isOpenByOwner
                        ? projectDetails?.themeColor
                        : "#d9deda",
                  }}
                >
                  {getProjectName(projectDetails?.name)}
                </div>
                <span
                  className="svg_icon pointer"
                  style={{
                    backgroundColor:
                      projectDetails?.isJoin && !props?.isOpenByOwner
                        ? projectDetails?.themeColor
                        : "#d9deda",
                  }}
                  onClick={() => hideProject(props?.idx)}
                >
                  <svg
                    width="12px"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 10.64 10.64"
                  >
                    <line x1="0.32" y1="0.32" x2="10.32" y2="10.32" />
                    <line
                      className="cls-1"
                      x1="10.32"
                      y1="0.38"
                      x2="0.32"
                      y2="10.32"
                    />
                  </svg>
                </span>
              </div>
              {!edit.status &&
                !addUserAction.status &&
                !projectDocAction &&
                !addChatUserAction.status && (
                  <>
                    <div
                      style={{
                        borderBottomWidth: 1,
                        boxShadow: "0px 3px 3px -3px #535353",
                      }}
                    >
                      <div className="user_name bg-white-user">
                        <div className="row d-flex justify-content-between align-items-center">
                          <div className="col-md-8 pr-0">
                            <div className="user_profile p-0">
                              <div className="user_img text-truncate">
                                <p>{props.project.name?.trim()}</p>
                              </div>
                            </div>
                            <div style={{ marginLeft: 10, fontSize: 15 }}>
                              {projectDetails?.projectNo ? (
                                <>
                                  {isGerm ? DE.project_No : EN.project_No}
                                  <span style={{ color: "#000" }}>
                                    {projectDetails?.projectNo}
                                  </span>
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-md-4 pl-0">
                            <div className="follow justify-content-end">
                              {/* {projectDetails?.userRole == "admin" ? (
                                <> */}
                              <div
                                className="icon_right"
                                onClick={() => {
                                  setOpenSetting(true);
                                }}
                              >
                                <a>
                                  <img
                                    width={20}
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/img/settings.svg"
                                    }
                                  />
                                </a>
                              </div>
                              {/* </>
                              ) : (
                                ""
                              )} */}
                              <div className="project-action-btn text-right">
                                <img
                                  onClick={(e) => {
                                    setToggleInfo(!toggleInfo);
                                  }}
                                  width="15px"
                                  className={
                                    !toggleInfo
                                      ? "img-fluid arrow_down"
                                      : "img-fluid arrow_down toggle-project-info"
                                  }
                                  src={
                                    process.env.PUBLIC_URL +
                                    (props.project.isInternal
                                      ? "/assets/img/Arrow_bottom_internal.svg"
                                      : "/assets/img/Arrow_bottom.svg")
                                  }
                                  alt="Generic placeholder"
                                />
                              </div>
                              {!projectDetails?.isJoin &&
                                !props.project.viewByOwner && (
                                  <div className="project-action-btn text-right">
                                    <a
                                      href="#"
                                      onClick={(e) =>
                                        handleJoin(projectDetails)
                                      }
                                    >
                                      <img
                                        width={22}
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/img/Add.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                )}
                              {!props.project.isInternal ||
                                (!props.project.isInternal &&
                                  props.project.viewByOwner) ? (
                                projectDetails?.full_address ? (
                                  <div className="project-action-btn text-right">
                                    <a
                                      href="#"
                                      onClick={(e) =>
                                        handleShow(projectDetails)
                                      }
                                    >
                                      <img
                                        width={16}
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/img/Location.svg"
                                        }
                                      />
                                    </a>
                                  </div>
                                ) : (
                                  <></>
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        ref={infWin}
                        className={
                          toggleInfo
                            ? "details_inner bg-white-user"
                            : "details_inner bg-white-user d-none"
                        }
                        style={{
                          marginLeft: "10px",
                          marginRight: "10px",
                          boxShadow: "none",
                          top: -10,
                        }}
                      >
                        <div className="details-item">
                          <p
                            style={{
                              color: projectDetails?.isJoin
                                ? "#737373"
                                : "#d9deda",
                            }}
                          >
                            {projectDetails?.full_address}
                          </p>
                          <p
                            style={{
                              color: projectDetails?.isJoin
                                ? "#737373"
                                : "#d9deda",
                            }}
                          >
                            {projectDetails?.city}
                          </p>
                          <p
                            style={{
                              color: projectDetails?.isJoin
                                ? "#737373"
                                : "#d9deda",
                            }}
                          >
                            {isGerm ? DE.opened_by : EN.opened_by}
                            <span
                              style={{
                                color: projectDetails?.isJoin
                                  ? props.project.isInternal
                                    ? KJUUP_COLORS.internal
                                    : KJUUP_COLORS.base_color
                                  : "#d9deda",
                              }}
                            >
                              {" "}
                              {projectDetails?.openedBy}
                            </span>
                          </p>
                        </div>

                        {!props.project.viewByOwner && props.project.isJoin ? (
                          <a
                            className="pointer"
                            onClick={(e) => handleEdit(projectDetails?.id)}
                          >
                            <img
                              width={20}
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/img/edit-pen-s.svg"
                              }
                              alt="Generic placeholder"
                            />
                          </a>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div
                      className="wscroll right_wscroll project_scrool"
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        flex: 1,
                      }}
                    >
                      <CustomScrollbars
                        autoHide
                        autoHideTimeout={200}
                        autoHideDuration={200}
                        className="bottom-bar radius-0"
                      >
                        <div className="select_icon bg-white-user mb-0">
                          <div className="">
                            <h3
                              className="middle-title pointer"
                              onClick={(e) => {
                                setShowMember(!showMember);
                                getMembers();
                              }}
                            >
                              <span>
                                {" "}
                                <img
                                  width={24}
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/img/project-member.svg"
                                  }
                                />
                              </span>
                            </h3>
                          </div>
                          <a
                            className="pointer"
                            onClick={(e) => {
                              if (
                                !props.project.viewByOwner &&
                                props.project.isJoin
                              ) {
                                setAddUserAction({
                                  status: true,
                                  id: id,
                                  addAsMember: true,
                                  isInternal: props.project.isInternal,
                                  cID: props.project.cID,
                                });
                              }
                            }}
                          >
                            <img
                              width={28}
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/img/add-to-project.svg"
                              }
                              alt="Generic placeholder"
                              style={{
                                opacity:
                                  props.project.viewByOwner ||
                                    !props.project.isJoin
                                    ? 0.5
                                    : 1,
                              }}
                            />
                          </a>
                          <a
                            className="pointer"
                            onClick={(e) => {
                              setProjectDocAction(true);
                            }}
                          >
                            <img
                              width={32}
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/img/Media-Links-Docs.svg"
                              }
                              alt="Generic placeholder"
                            />
                          </a>
                        </div>
                        {/* {showMember && (
                          <div className="admin-content m-0">
                            <div className="search_div" style={{ marginTop: 10, marginLeft: 5, marginRight: 5, marginBottom: 10, border: '1px solid #dfdfdf', borderRadius: 5, overflow: "hidden" }}>
                              <div className="search">
                                <Form.Group controlId="exampleForm.ControlInput1">
                                  <Form.Control
                                    autocomplete="off"
                                    value={searchUser}
                                    type="search"
                                    placeholder={isGerm ? DE.search : EN.search}
                                    onChange={(e) => filterAdmins(e.target.value)}
                                  />
                                </Form.Group>
                                <BsSearch />
                              </div>
                            </div>
                            {users?.map((user, index) => (
                              <div key={index}>
                                {user.names?.length > 0 && (
                                  <h5
                                    className="p-2 mb-0"
                                    style={{ background: "#f7f7f7" }}
                                  >
                                    {user.name}
                                  </h5>
                                )}
                                {user?.names?.map((u, i) => (
                                  <div key={i} className="bg-white">
                                    <div
                                      style={{
                                        flexDirection: "row",
                                        display: "flex",
                                        padding: 10,
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <div>
                                        <div
                                          className="admin-control"
                                          style={
                                            u.requestNotYetAccepted && {
                                              opacity: "0.5",
                                            }
                                          }
                                        >
                                          <img
                                            className="m-0"
                                            width={47}
                                            height={47}
                                            style={{ borderRadius: 4 }}
                                            src={
                                              u.avatar
                                                ? BASE_URL + u.avatar
                                                : process.env.PUBLIC_URL +
                                                "/assets/img/head.svg"
                                            }
                                            alt="Generic placeholder"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        style={
                                          u.requestNotYetAccepted
                                            ? {
                                              opacity: "0.5",
                                              flex: 1,
                                              paddingLeft: 10,
                                            }
                                            : {
                                              flex: 1,
                                              paddingLeft: 10,
                                            }
                                        }
                                      >
                                        <p
                                          className="small font-weight-bold m-0 p_user_name pointer"
                                          onClick={() =>
                                            navigateUserProfile(u?.id)
                                          }
                                        >
                                          {u.fullName
                                            ? convertCapitalLeter(u.fullName)
                                            : ""}
                                        </p>
                                        {u.requestNotYetAccepted && (
                                          <span style={{ fontSize: "12px" }}>
                                            {isGerm ? DE.PENDING : EN.PENDING}{" "}
                                          </span>
                                        )}
                                        <p className="m-0 small p_company">
                                          {u.companyName}
                                        </p>
                                        {!u.requestNotYetAccepted && (
                                          <div>
                                            <p className="m-0 small p_postion">
                                              {isGerm ? DE.branch : EN.branch}:{" "}
                                              <i>
                                                {convertBranchRole(
                                                  u.buildingRole,
                                                  isGerm
                                                )}
                                              </i>
                                            </p>
                                            <p className="m-0 small p_postion">
                                              {u.buldingAdmin
                                                ? isGerm
                                                  ? DE.admin
                                                  : EN.admin
                                                : isGerm
                                                  ? DE.member
                                                  : EN.member}
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                      {projectDetails?.userRole === "admin" && (
                                        <div
                                          onClick={async () => {
                                            await $crud.confirm({
                                              title: "",
                                              textContent: isGerm
                                                ? DE.remove_project_admin
                                                : EN.remove_project_admin,
                                              options: {
                                                ok: isGerm
                                                  ? DE.confirm
                                                  : EN.confirm,
                                                cancel: isGerm
                                                  ? DE.cancel
                                                  : EN.cancel,
                                              },
                                            });
                                            _deleteMemberFromProject(
                                              u.id,
                                              i,
                                              index
                                            );
                                          }}
                                          style={{
                                            justifyContent: "center",
                                            paddingRight: 10,
                                          }}
                                        >
                                          <img
                                            width={18}
                                            className="pointer"
                                            onClick={(e) => { }}
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/img/trashcan.svg"
                                            }
                                            alt="Generic placeholder"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        )} */}
                        <div style={{ flex: 1 }}>
                          <div className="chats_user bg-white-user">
                            <h5>
                              Chats
                              {!props.project.viewByOwner &&
                                props.project.isJoin && (
                                  <a
                                    href="#"
                                    onClick={() => {
                                      setAddChatUserAction({
                                        status: true,
                                        id: id,
                                        isInternal: props.project.isInternal,
                                        cID: props.project.cID,
                                      });
                                    }}
                                  >
                    <BsFillPlusCircleFill size={22} color={!isKjuup ? KJUUP_COLORS.base_color : KJUUP_COLORS.internal} />

                                    {/* <img
                                      width={20}
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/img/create-article.svg"
                                      }
                                      alt="Generic placeholder"
                                    /> */}
                                  </a>
                                )}
                            </h5>
                            <div className="search">
                              <Form.Group controlId="exampleForm.ControlInput1">
                                <Form.Control
                                  type="search"
                                  onChange={(e) => searchProject(e)}
                                  placeholder={isGerm ? DE.search : EN.search}
                                />
                              </Form.Group>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/img/search.svg"
                                }
                                alt="Generic placeholder"
                              />
                            </div>
                            <div className="company_services chat_services project_chat_user">
                              {chatGroups &&
                                chatGroups.map((group, index) => {
                                  let chatUser = group.users.filter(
                                    (u) => u.id == user.id
                                  );
                                  let opened = openGroups?.findIndex(
                                    (e) => e.id == group.id
                                  );
                                  return (
                                    <div
                                      className={
                                        chatUser?.length > 0 &&
                                          chatUser[0]?.isLeave
                                          ? "company_centact pointer disable-group"
                                          : "company_centact pointer"
                                      }
                                      style={
                                        opened !== -1
                                          ? {
                                            backgroundColor: props.project
                                              .isInternal
                                              ? KJUUP_COLORS.internalLight
                                              : "#fce6d7",
                                          }
                                          : { backgroundColor: "#f7f7f7" }
                                      }
                                    >
                                      <div
                                        className="website"
                                        onClick={(e) => {
                                          if (!activeForward) openChat(group);
                                          else {
                                          }
                                        }}
                                      >
                                        <div className="website_img">
                                          <div
                                            className="groupName position-relative"
                                            style={{
                                              border: `1px solid ${chatUser?.length > 0 &&
                                                chatUser[0]?.isLeave
                                                ? "grey"
                                                : projectDetails?.themeColor
                                                }`,
                                              color:
                                                chatUser?.length > 0 &&
                                                  chatUser[0]?.isLeave
                                                  ? "grey"
                                                  : projectDetails?.themeColor,
                                            }}
                                          >
                                            {group.name.includes(
                                              isGerm
                                                ? DE.all_member_of_project
                                                : EN.all_member_of_project
                                            )
                                              ? getProjectName(
                                                projectDetails?.name
                                              )
                                              : getProjectName(group.name)}
                                            {props.project.viewByOwner &&
                                              (!props.project.isInternal ? (
                                                <img
                                                  width={20}
                                                  className="admin-view-eye"
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/img/view_company_owner.png"
                                                  }
                                                />
                                              ) : (
                                                <div
                                                  width={20}
                                                  className="admin-view-eye"
                                                  style={{ top: -13 }}
                                                >
                                                  <svg
                                                    width="20"
                                                    height="20"
                                                    viewBox="0 0 57 37"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                  >
                                                    <g clip-path="url(#clip0_157_2)">
                                                      <path
                                                        d="M57 18.0382V18.9206C56.905 19.1147 56.813 19.3118 56.712 19.5089C51.6399 29.195 43.6703 34.9837 32.7646 36.65C25.9454 37.6912 19.4794 36.3897 13.4425 33.1203C7.40555 29.8509 2.93758 25.0858 0 18.9236V18.0411C0.0890625 17.8632 0.175154 17.6852 0.267186 17.5102C5.98203 6.67405 15.0204 0.719171 27.3348 0.0514706C35.953 -0.419155 43.4803 2.58402 49.8601 8.31977C52.9105 11.0715 55.2455 14.3497 57 18.0382ZM17.2187 18.4794C17.225 21.4397 18.4164 24.2767 20.5313 26.3678C22.6463 28.4589 25.5121 29.6332 28.5 29.6332C31.4897 29.6332 34.3571 28.4574 36.4723 26.3641C38.5876 24.2708 39.7777 21.4312 39.7812 18.4691C39.7749 15.5075 38.5826 12.6694 36.466 10.5781C34.3495 8.48685 31.4817 7.31333 28.4926 7.31528C25.5032 7.31645 22.6365 8.49317 20.5221 10.587C18.4077 12.6808 17.2185 15.5205 17.2158 18.4823L17.2187 18.4794Z"
                                                        fill="#288f6c"
                                                      />
                                                      <path
                                                        d="M28.5357 11.0892C29.5191 11.0897 30.4926 11.2834 31.4 11.6591C32.3073 12.0347 33.1305 12.5849 33.822 13.2777C34.5134 13.9706 35.0593 14.7924 35.4282 15.6956C35.797 16.5988 35.9815 17.5655 35.9709 18.5398C35.9645 19.5099 35.765 20.4693 35.3839 21.363C35.0027 22.2566 34.4474 23.067 33.7498 23.7478C33.0521 24.4285 32.2257 24.9662 31.318 25.3301C30.4103 25.6939 29.4391 25.8768 28.4599 25.8683C27.4765 25.8672 26.5029 25.673 25.5956 25.297C24.6883 24.9209 23.8653 24.3705 23.1739 23.6774C22.4826 22.9844 21.9367 22.1625 21.5678 21.2592C21.1989 20.3559 21.0143 19.3892 21.0247 18.4148C21.0309 17.4447 21.2303 16.4854 21.6114 15.5918C21.9925 14.6982 22.5478 13.8879 23.2456 13.2074C23.9433 12.5269 24.7698 11.9895 25.6776 11.626C26.5853 11.2625 27.5566 11.0801 28.5357 11.0892Z"
                                                        fill="#288f6c"
                                                      />
                                                    </g>
                                                    <defs>
                                                      <clipPath id="clip0_157_2">
                                                        <rect
                                                          width="57"
                                                          height="37"
                                                          fill="white"
                                                        />
                                                      </clipPath>
                                                    </defs>
                                                  </svg>
                                                </div>
                                              ))}
                                            {Boolean(group.unseenChatCount) && (
                                              <div
                                                style={{
                                                  position: "absolute",
                                                  height: "100%",
                                                  width: "100%",
                                                  display: "flex",
                                                  alignItems: "flex-start",
                                                  justifyContent: "flex-end",
                                                }}
                                              >
                                                <div
                                                  style={{
                                                    marginTop: "-10px",
                                                    marginRight: "-8px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    height: "20px",
                                                    width: "20px",
                                                    borderRadius: 100,
                                                    backgroundColor:
                                                      KJUUP_COLORS.base_color,
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      color: "white",
                                                      fontWeight: "bold",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    {group.unseenChatCount}
                                                  </span>
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className="details-company forword-checkbox_main">
                                          <h5
                                            className={
                                              chatUser[0]?.isLeave
                                                ? "text-grey"
                                                : ""
                                            }
                                          >
                                            {group.defaultChatGroup
                                              ? isGerm
                                                ? "Alle"
                                                : "All"
                                              : group.name.length < 40
                                                ? group.name.replace(
                                                  "All members of the room",
                                                  isGerm
                                                    ? DE.all_member_of_project
                                                    : EN.all_member_of_project
                                                )
                                                : group.name
                                                  .replace(
                                                    "All members of the room",
                                                    isGerm
                                                      ? DE.all_member_of_project
                                                      : EN.all_member_of_project
                                                  )
                                                  .substr(0, 39) + "..."}
                                            {!activeForward && (
                                              <span className="date">
                                                {moment(
                                                  group.messageDate
                                                ).format("DD.MM.YY")}
                                              </span>
                                            )}
                                          </h5>
                                          {activeForward &&
                                            group.id != gpId &&
                                            !chatUser[0]?.isLeave && (
                                              <div className="forward-checkbox ">
                                                <label className="container_checkbox">
                                                  <input
                                                    type="checkbox"
                                                    onClick={() =>
                                                      selectIds(group.id, group)
                                                    }
                                                  />
                                                  <span className="checkmark"></span>
                                                </label>
                                              </div>
                                            )}
                                          {storedDraftMessage.find(
                                            (m) => m.group === group.id
                                          ) && !group.isBlock ? (
                                            <div className="draft-message m-0">
                                              <p>
                                                [draft]{" "}
                                                {
                                                  storedDraftMessage.find(
                                                    (m) => m.group === group.id
                                                  )?.message
                                                }
                                              </p>
                                            </div>
                                          ) : (
                                            <div
                                              onClick={(e) => openChat(group)}
                                              className="lastMessage m-0"
                                            >
                                              {group?.lastMessageDeleted ? (
                                                <p>
                                                  {isGerm
                                                    ? DE.message_delete
                                                    : EN.message_delete}
                                                </p>
                                              ) : (
                                                <p>
                                                  {onlyUsername(
                                                    group.lastMessage.replaceAll(
                                                      "<br/>",
                                                      " "
                                                    )
                                                  )}
                                                </p>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                            <div className="project_verson"></div>
                          </div>
                        </div>
                        <ShareProjectMapDialog
                          company={projectDetails}
                          show={show}
                          handleClose={handleClose}
                        />
                        {showCompanyDialog && (
                          <CompanyListDialog
                            handleClose={(e) => setCompanyDialog(false)}
                            setParam={setParam}
                            companies={companies}
                            handleReJoinCompany={submitReJoinCompany}
                          />
                        )}
                      </CustomScrollbars>
                      {/* {!activeForward ?
                        <div style={{}}>
                          {projectDetails?.isJoin && !props.project.viewByOwner && (
                            <div className="leave_company pl-3 pr-3 pb-2">
                              <h5
                                className="svg_icon"
                                onClick={(e) => leaveProject(projectDetails?.id)}
                              >
                                <svg
                                  className="mr-3"
                                  width="17px"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 10.64 10.64"
                                >
                                  <line
                                    x1="0.32"
                                    y1="0.32"
                                    x2="10.32"
                                    y2="10.32"
                                  />
                                  <line
                                    className="cls-1"
                                    x1="10.32"
                                    y1="0.38"
                                    x2="0.32"
                                    y2="10.32"
                                  />
                                </svg>
                                {isGerm ? DE.leave_project : EN.leave_project}
                              </h5>
                            </div>
                          )}
                        </div>
                        :
                        <></>
                      } */}
                      {activeForward && (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                            marginBottom: 15,
                            marginLeft: 10,
                            marginRight: 10,
                          }}
                        >
                          <div
                            onClick={handleCancel}
                            style={{
                              flex: 1,
                              textAlign: "center",
                              padding: 10,
                              border: "2px solid #000",
                              padding: 10,
                              borderRadius: 8,
                              marginRight: 10,
                            }}
                          >
                            <a
                              onClick={handleCancel}
                              className=""
                              href="#"
                              style={{
                                fontSize: 15,
                                fontWeight: "700",
                                color: "#000",
                              }}
                            >
                              {isGerm ? DE.cancel : EN.cancel}
                            </a>
                          </div>
                          <div
                            nClick={handleForward}
                            style={{
                              flex: 1,
                              textAlign: "center",
                              border: "2px solid",
                              padding: 10,
                              borderRadius: 8,
                              color: KJUUP_COLORS.base_color,
                            }}
                          >
                            <a
                              onClick={handleForward}
                              className="text-orange"
                              href="#"
                              style={{ fontSize: 15, fontWeight: "700" }}
                            >
                              {isGerm ? DE.forward : EN.forward}
                            </a>
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              {edit.status && (
                <EditProject
                  project_id={edit.id}
                  handleEditClose={() => {
                    setEdit({
                      status: false,
                      id: null,
                      isInternal: props.project.isInternal,
                    });
                  }}
                  isInternal={props.project.isInternal}
                  isAdmin={projectDetails?.userRole == "admin" ? true : false}
                  name={projectDetails?.name}
                />
              )}
              {showMember ? (
                <ProjectMembers
                  filterAdmins={filterAdmins}
                  users={users}
                  searchUser={searchUser}
                  _deleteMemberFromProject={_deleteMemberFromProject}
                  project_id={props.project.id}
                  projectDetails={projectDetails}
                  handleEditClose={() => {
                    setShowMember(false);
                  }}
                  isInternal={props.project.isInternal}
                  members={users}
                  name={props.project.name}
                  isJoin={projectDetails.isJoin}
                  viewByOwner={props.project.viewByOwner}
                  leaveProject={leaveProject}
                  isAdmin={projectDetails?.userRole == "admin" ? true : false}
                />
              ) : (
                <></>
              )}
              {openSetting ? (
                <ProjectSettings
                  project_id={props.project.id}
                  handleEditClose={() => {
                    setOpenSetting(false);
                  }}
                  isInternal={props.project.isInternal}
                  members={users}
                  name={props.project.name}
                  isJoin={projectDetails.isJoin}
                  viewByOwner={props.project.viewByOwner}
                  leaveProject={leaveProject}
                  isAdmin={projectDetails?.userRole == "admin" ? true : false}
                />
              ) : (
                <></>
              )}
              {addUserAction.status && (
                <AddProjectUser
                  project_id={addUserAction.id}
                  handleEditClose={(isInternal, cID) => {
                    setAddUserAction({
                      status: false,
                      id: null,
                      isInternal: isInternal,
                      cID: cID,
                    });
                    getMembers();
                  }}
                  addAsMember={
                    addUserAction?.addAsMember
                      ? addUserAction?.addAsMember
                      : false
                  }
                  setAllUsers={setAllUsers}
                  allUsers={allUsers}
                  setAddChatUserAction={setAddChatUserAction}
                  setAddUserAction={setAddUserAction}
                  isInternal={props.project.isInternal}
                  cID={
                    addUserAction.cID
                      ? addUserAction.cID
                      : props.project.companyId ? props.project.companyId :
                        props.project.company_id ? props.project.company_id : props.project.company

                  }
                  Mems={members}
                />
              )}
              {addChatUserAction.status && (
                <AddProjectChatUser
                  addMember={() => {
                    setAddUserAction({
                      status: true,
                      id: id,
                      isInternal: props.project.isInternal,
                      cID: props.project.cID,
                    });
                    setAddChatUserAction({ status: false, id: null });
                  }}
                  users={users}
                  project_id={addChatUserAction.id}
                  handleEditClose={() => {
                    setAddChatUserAction({ status: false, id: null });
                    getMembers();
                  }}
                  setAllUsers={setAllUsers}
                  allUsers={allUsers}
                  isInternal={addChatUserAction.isInternal}
                  cID={
                    addChatUserAction.cID
                      ? addChatUserAction.cID
                      : props.project.companyId
                  }
                  Mems={members}
                  myMems={addChatUserAction?.myMems}
                />
              )}
              {viewLoader && (
                <div className="website_loader">
                  <div className="loader" />
                </div>
              )}
              {projectDocAction && (
                <BuildingMediaDocs
                  project_id={projectDetails?.id}
                  handleEditClose={() => {
                    setProjectDocAction(false);
                  }}
                  isInternal={props.project.isInternal}
                />
              )}
            </div>
          )}
          {props.groupChats &&
            user &&
            props.groupChats.map((chat, index) => (
              <div
                className="group-chats-wrapper"
                style={{ opacity: !visible ? 0 : 1 }}
              >
                {chat.building === id && (
                  <GroupConversation
                    key={index}
                    projectDetails={projectDetails}
                    members={members}
                    project={props.project}
                    handleClose={(id) => {
                      openChat({ id });
                    }}
                    chat={chat}
                    addMember={_addMemberCallBack}
                    openChatArr={props.groupChats}
                    getChats={getChats}
                    leaveProject={leaveProject}
                    isInternal={addChatUserAction.isInternal}
                    cID={addChatUserAction.cID}
                    users={users}
                    fromActivity={
                      props.fromActivity
                        ? props.fromActivity
                        : !projectDetails?.isJoin
                    }
                  />
                )}
              </div>
            ))}
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  const {
    openProjects,
    updateProjectActionFire,
    projects,
    groupChats,
    forwardGroupMessages,
    draftMessages,
    updateGroupMessage,
    isOpenByOwner,
    openBottomProjects,
  } = state;
  return {
    projects: projects,
    openProjects: openProjects,
    updateProjectActionFire: updateProjectActionFire,
    groupChats: groupChats,
    forwardGroupMessages: forwardGroupMessages,
    draftMessages: draftMessages,
    updateGroupMessage: updateGroupMessage,
    isOpenByOwner,
    openBottomProjects: openBottomProjects,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateProjects: (projects) =>
      dispatch({ type: PROJECTS, projects: projects }),
    updateOpenProjectsList: (projects) =>
      dispatch({ type: OPEN_PROJECTS, openProjects: projects }),
    updateProjectAction: (value) =>
      dispatch({
        type: UPDATE_PROJECT_ACTION_FIRE,
        updateProjectActionFire: value,
      }),
    updateGroupChats: (chats) =>
      dispatch({ type: GROUP_CHATS, groupChats: chats }),
    updateForwardGroupMessage: (groupMessages) =>
      dispatch({
        type: FORWARD_GROUP_MESSAGES,
        forwardGroupMessages: groupMessages,
      }),
    updateDraftMessages: (msg) =>
      dispatch({ type: DRAFT_MESSAGES, draftMessages: msg }),
    isOwnerOpenProject: (open) => dispatch({ type: IS_OPEN_BY_OWNER, open }),
    updateAllBadgeCount: (count) =>
      dispatch({ type: ALL_BADGE_COUNT, unSeenAllCount: count }),
    updateBottomOpenProjects: (projects) =>
      dispatch({ type: OPEN_BOTTOM_PROJECTS, openBottomProjects: projects }),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(memo(ProjectDetailSection)));
