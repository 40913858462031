import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { DE } from "../../Languages/DE";
import { EN } from "../../Languages/EN";
import LanguageContext from "../../helper/LanguageContext";
import { callAPI } from "../../helper/apiUtils";
import { Scrollbars } from "react-custom-scrollbars";
import _ from "lodash";
import { BASE_URL, KJUUP_COLORS, capitalizeFirstLetter } from "../../helper/constants";
import { $crud } from "../../helper/CrudFactory";
import { BsFillXCircleFill } from "react-icons/all";
import InviteProjectUser from "./InviteProjectUser";
import { convertBranchRole, convertPosition } from "../../helper/convertor";
import { useSelector } from "react-redux";
import { $user } from "../../helper/UserFactory";
import Accordion from 'react-bootstrap/Accordion'
import { BsChevronUp, BsChevronDown, BsSearch, BsX } from "react-icons/bs";
import { Card, CloseButton } from 'react-bootstrap';
import { Discuss, Rings, Oval } from 'react-loader-spinner'
import InviteExternalComponent from "../../components/InviteExternalComponent";
import ImageWithPlaceholder from "../../components/ImageWithPlaceholder";


const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    borderRadius: 6,
    backgroundColor: "rgba(240, 132, 56, 0.8)",
  };
  return (
    <div
      className="thumb-style"
      style={{ ...style, ...thumbStyle }}
      {...props}
    />
  );
};

const CustomScrollbars = (props) => (
  <Scrollbars
    renderThumbHorizontal={renderThumb}
    renderThumbVertical={renderThumb}
    {...props}
  />
);

function AddProjectUser(props) {
  const { isGerm } = React.useContext(LanguageContext);
  const [inviteLink, setInviteLink] = useState(false);
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [userList, setUsersList] = useState([]);
  const [postUser, setPostUser] = useState(props.allUsers);
  const id = props.project_id;
  const [isAllMember, setIsAllMember] = useState(false)
  const user = useSelector((state) => state.user);
  const [profile, setProfile] = useState({});
  const [display, setDisplay] = useState("0")
  const [comps, setComps] = useState([])
  const [selectedIds, setSelectedIds] = useState([])
  const [searchList, setSearchList] = useState([])
  const [allTogether, setTogether] = useState([])
  const [loader, setLoader] = useState(false)
  const [allToSearch, setAllToSearch] = useState([])
  const [allToSearchBack, setAllToSearchBack] = useState([])
  const getCompanies = async () => {
    const { data } = await callAPI(
      !props.isInternal ?
        props.groupId ?
          `/building/${id}/getMyBuildingContacts?groupId=${props.groupId}` :
          `/building/${id}/getMyBuildingContacts` :
        props.groupId ?
         `/building/${id}/getMyBuildingContacts?company_id=${props.cID}&groupId=${props.groupId}` :
          `/building/${id}/getMyBuildingContacts?company_id=${props.cID}`,
      {},
      "GET"
    );
    if (data.status) {
      const arrList = await data.users;
      await CreateList(arrList);
    }
  };

  const CreateList = async (users) => {
    const arr = await _.uniqBy(
      users?.map((user) => {
        let lname = user.lastName.trim()
        return { name: lname[0].toUpperCase() };
      }),
      "name"
    );
    const updatedArr = _.sortBy(arr, ["name"]);
    const usersList = updatedArr.map((a) => {
      a.names = users.filter((u) => {
        let lname = u.lastName.trim()
        return (lname[0].toUpperCase() == a.name)
      });
      return a;
    });
    setUsers(usersList);
    setUsersList(usersList);
    setAllToSearch([...users])
    setAllToSearchBack([...users])
    getAllCompnies()
  };

  useEffect(() => {
    getCompanies();
  }, []);

  const getAllCompnies = async () => {
    const { data } = await callAPI('/company/getCompaniesWithUser',
      {},
      "GET"
    );
    let rmpss = []
    if (data.companies) {
      let tmp = data.companies.map(e => {
        e.employees = e.users.filter((value, index, self) => {
          return index === self.findIndex((t) => (
            t.id === value.id
          ))
        })
        e.employees = e.employees.filter((x) => {
          let indArr = props.Mems ? props.Mems : []
          let ind = indArr.findIndex(b => b.id == x.id)
          if (ind == -1) {
            return true
          }
          else {
            return false
          }
        })
        rmpss = [...rmpss, ...e.employees]
        return { ...e }
      })
      setComps([...tmp])
      rmpss = rmpss.filter((value, index, self) => {
        return index === self.findIndex((t) => (
          t.id === value.id
        ))
      })
      setTogether([...rmpss])
    }
    else {
      let tmp = [...users]
      let allToSearch = []
      tmp = tmp.map((e) => {
        e.names = e.names.filter(x => {
          let indd = allTogether.findIndex(z => z.id == x.id)
          if (indd !== -1) {
            return false
          }
          else {
            return true
          }
        })
        allToSearch = [...allToSearch, ...e.names]
        return { ...e }
      })
      setTogether([...allToSearch])
    }
  };

  useEffect(() => {
    if (!props.isInternal) {
      let tmp = [...users]
      let allToSearch = []
      tmp = tmp.map((e) => {
        e.names = e.names.filter(x => {
          let indd = allTogether.findIndex(z => z.id == x.id)
          if (indd !== -1) {
            return false
          }
          else {
            return true
          }
        })
        allToSearch = [...allToSearch, ...e.names]
        return { ...e }
      })
      allToSearch = [...allToSearch, ...allTogether]
      allToSearch = allToSearch.filter((value, index, self) => {
        return index === self.findIndex((t) => (
          t.id === value.id
        ))
      })
      console.log('------>tmo', tmp, allToSearch)
      setUsers([...tmp])
      setUsersList([...tmp])
      setAllToSearch([...allToSearch])
      setAllToSearchBack([...allToSearch])
    }
  }, [allTogether])

  useEffect(() => {
    setSearchList([...comps])
  }, [comps])

  useEffect(() => {
    getAllCompnies()
  }, [user]);

  const filterUsers = async (searchValue) => {
    setSearch(searchValue);
    searchValue = searchValue.trim()
    if (searchValue) {
      let xx = [...allToSearchBack]
      let xxx = xx.filter((itm) => {
        if (itm.firstName.toLowerCase().startsWith(searchValue.toLowerCase()) || itm.lastName.toLowerCase().startsWith(searchValue.toLowerCase()) || itm.fullName.toLowerCase().startsWith(searchValue.toLowerCase())) {
          return true
        }
      })
      setAllToSearch([...xxx])
    }
    else {
      setAllToSearch([...allToSearchBack])
    }
    // console.log(typeof text);

    // if (text !== "") {
    //   let userListFilter = userList;
    //   let positionIndex = await users.findIndex(
    //     (item) => e.name.toLowerCase() === text.toLowerCase()
    //   );

    //   if (positionIndex !== -1) {
    //     console.log(positionIndex);
    //     userListFilter = [userListFilter[positionIndex]];
    //     console.log(userListFilter);
    //   } else {
    //     let userListFilter = await userList.map((item) => {
    //       console.log(positionIndex);
    //       let filterName = e.names.filter((i) =>
    //         i.fullName.toLowerCase().includes(text.toLowerCase())
    //       );
    //       return { ...item, names: filterName };
    //     });
    //     userListFilter = userListFilter.filter(
    //       (position) => position.names.length > 0
    //     );
    //     console.log(userListFilter);
    //     setUsers(userListFilter);
    //   }
    // } else {
    //   setUsers(userList);
    // }
  };

  const addProjectUser = async () => {
    if (props?.addAsMember) {
      const id = props.project_id;
      if (selectedIds.length > 0) {
        let fnn = selectedIds.map(e => {
          return { user: e }
        })
        fnn = fnn.filter(e => e.user != user.id)
        console.log('---------->selecteed id', fnn)
        setLoader(true)
        const { data } = await callAPI(props.isInternal ? `/building/${id}/add?companyId=${props.cID}` : `/building/${id}/add`, {}, "PUT", {
          id: id,
          users: fnn,
        });
        $crud.notify({
          type: data.status ? "success" : "error",
          message: data.message,
        });
        setPostUser([]);
        setSelectedIds([])
        setLoader(false)
        props.handleEditClose();
      } else {
        $crud.notify({
          type: "error",
          message: "Please select users to continue",
        });
      }
    } else {
      let compUses = []
      comps.map((e) => {
        if (e.employees) {
          let sll = e.employees.filter((x) => {
            return selectedIds.includes(x.id)
          })
          let slll = sll.map((e) => {
            return { ...e, nonmember: true, addStatus: true }
          })
          compUses = [...compUses, ...slll]
        }
      })
      let ids = []
      users.map((u) => {
        u.names.map(x => {
          let isIn = selectedIds.includes(x.id)
          if (isIn) {
            ids = [...ids, ...[{ ...x, nonmember: true, addStatus: true }]]
          }
        })
      });
      let MN = [...compUses, ...ids]
      let xx = MN.filter((value, index, self) => {
        return index === self.findIndex((t) => (
          t.id === value.id
        ))
      })
      props.setAddUserAction({ status: false, id: null, isInternal: props.isInternal, cID: props.cID, myMems: xx });
      props.setAddChatUserAction({ status: true, id: props.project_id, isInternal: props.isInternal, cID: props.cID, myMems: xx });
    }
  };
  const removeUser = async (u) => {
    const updatedUsers = postUser.filter((user) => user?.id !== u.id);
    const updatedAllUsers = props.allUsers.filter((user) => user?.id !== u.id);
    setPostUser(updatedUsers);
    props.setAllUsers(updatedAllUsers);
    const updateUser = await users.map((usr) => {
      usr.names = usr.names.map((name) => {
        if (name.id === u.id) {
          return { ...name, addStatus: false };
        } else {
          return name;
        }
      });
      return usr;
    });
    setUsers(updateUser);
  };

  const _getPosition = (cid, comp) => {
    if (Array.isArray(comp)) {
      let ind = comp.findIndex(e => e.id == cid)
      if (ind !== -1) {
        return capitalizeFirstLetter(comp[ind].position)
      }
    }
  }

  const handleClass = (index, compId, hasEmps) => {
    if (display === index) {
      setDisplay(null)
    } else {
      setDisplay(index)
      if (!hasEmps)
        _getEmps(compId, index)
    }
  }


  const handleSearchClass = (index, compId, hasEmps) => {
    let list = [...searchList]
    list[index].isOpen = !list[index].isOpen
    setSearchList([...list])
    // if (!hasEmps)
    //   _getEmps(compId, index)
  }

  const _getEmps = async (compId, index) => {
    let tmp = [...comps]
    tmp[index].isGettingEmps = true
    setComps([...tmp])
    let xxt = [...users]
    const { data } = await callAPI(`/company/${compId}/admin`, {}, 'GET')
    if (data.status) {
      let xcv = []
      for (let obj of data.coworker) {
        let fx = props.Mems.findIndex(e => e.id == obj.id)
        if (fx == -1) {
          tmp.map(e => {
            if (e.employees) {
              let allIds = e.employees.map(e => {
                return e.id
              })
              let mainArray = [...selectedIds]
              mainArray = mainArray.filter(item => allIds?.includes(item));
              mainArray = [...new Set([...mainArray])]
              let filteredrmps = e.employees.map(j => {
                if (obj.id == j.id) {
                  obj.isEmpSelected = j.isEmpSelected
                }
                return { ...j }
              })
              return { ...e, employees: filteredrmps, inviteAllSelcted: (mainArray.length == e.employees?.length) ? true : false }
            }
            else {
              return { ...e }
            }
          })
          xcv.push(obj)
        }
        else {
          // obj = { ...obj, isInvited: true }
          // xcv.push(obj)
        }
      }
      let ll = xcv.filter(e => e.isEmpSelected)
      let llx = xcv.filter(e => e.isInvited)
      if (ll.length == xcv.length) {
        tmp[index].inviteAllSelcted = true
      }
      if (llx.length == xcv.length) {
        tmp[index].hideInviteAll = true
      }
      tmp[index].employees = (xcv && xcv.length > 0) ? xcv : null
      tmp[index].isGettingEmps = false
      setComps([...tmp])
      tmp.map(e => {
        if (e.employees) {
          e.employees.map(j => {
            xxt.map((d, i) => {
              if (d.names) {
                d.names.map((g, k) => {
                  if (j.id == g.id) {
                    xxt[i].names.splice(k, 1)
                  }
                })
              }
            })
            return { ...j }
          })
          return { ...e }
        }
        else {
          return { ...e }
        }
      })
      setUsers([...xxt])
    }
    else {
      tmp[index].employees = null
      tmp[index].isGettingEmps = false
      setComps([...tmp])
    }
    console.log('-------------->emps', data)
  }

  const _getDisabled = () => {
    let x = []
    for (let obj of comps) {
      if (obj.employees) {
        let b = obj.employees?.filter(e => e.isEmpSelected)
        if (b) {
          let t = b.map(e => {
            const data = {
              user: e.id,
            };
            return data;
          })
          x = [...x, ...t]
        }
      }
    }
    if (x.length > 0) {
      return true
    }
    else {
      let x = []
      users.map((e, i) => {
        e.names.map((j, k) => {
          if (j.addStatus) {
            x.push(j)
          }
        })
      })
      return x.length > 0 ? true : false
    }
  }
  return (
    <React.Fragment>
      {!inviteLink && (
        <div class="company_section company_list right_company border-radius-0" style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <div className="company_header">
            <div class="heading_icon m-0">
              <a
                onClick={(e) => props.handleEditClose(props.isInternal, props.cID)}
                className="main_heading pointer"
                style={{
                  color: props.isInternal ? KJUUP_COLORS.internal : "#f08438",
                }}
              >
                {!props.isInternal ?
                  <img
                    width={10}
                    src={process.env.PUBLIC_URL + "/assets/img/back.svg"}
                  />
                  :
                  <img
                    width={10}
                    className="custom-svg"
                    src={process.env.PUBLIC_URL + "/assets/img/backx.svg"}
                  />
                }
                {isGerm ? DE.cancel : EN.cancel}
              </a>
              <div
                style={{
                  marginLeft: "10px",
                  fontSize: "18px",
                  color: "#000",
                  fontWeight: 500,
                  clear: "both",
                  display: "inline-block",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                {isGerm ? DE.add_user : EN.add_user}
              </div>
              <a
                style={{
                  color: (selectedIds.length > 0 && !loader) ? props.isInternal ? KJUUP_COLORS.internal : "#f08438" : "#737373",
                }}
                onClick={() => {
                  if (!loader)
                    addProjectUser()
                }}
                className="main_heading pointer"
              >
                {isGerm ? DE.continue : EN.continue}
                <img
                  style={{ color: "#f08438" }}
                  width={10}
                  src={
                    process.env.PUBLIC_URL + (selectedIds.length > 0 ? props.isInternal ? "/assets/img/arrowRightGreen.svg" : "/assets/img/arrowRight.svg" : "/assets/img/arrow-rightdeselected.svg")
                  }
                />
              </a>
            </div>
          </div>
          <div className="company_list_ver" style={{ flex: 1, display: "flex", flexDirection: "column" }}>
            <div className="my-scroll min_height m-0 add_scrool" style={{ flex: 1 }}>
              <CustomScrollbars
                autoHide
                autoHideTimeout={500}
                autoHideDuration={200}
                className="bottom-bar"
              >
                {!props.isInternal ?
                  <InviteExternalComponent
                    onClick={(e) => setInviteLink(true)}
                    color={'#fff'}
                  />
                  :
                  <></>
                }
                <div className="invite p-2">
                  <div className="admin_right">
                    <div className="search_div">
                      <div className="search">
                        <Form.Group controlId="exampleForm.ControlInput1">
                          <Form.Control
                            autocomplete="off"
                            type="search"
                            onChange={(e) => filterUsers(e.target.value)}
                            placeholder={isGerm ? DE.search : EN.search}
                          />
                        </Form.Group>
                      </div>
                    </div>
                    {
                      props.isInternal ?
                        (users && users.length > 0) ?
                          <div
                            style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
                            onClick={() => {
                              let x = !isAllMember
                              setIsAllMember(x)
                              if (x) {
                                let tmpids = []
                                users.map(e => {
                                  e.names.map(x => {
                                    tmpids.push(x.id)
                                  })
                                })
                                setSelectedIds([...tmpids])
                              }
                              else {
                                setSelectedIds([])
                              }
                            }}
                            className="mx-1 my-3"
                          >
                            <div
                              style={{}}
                            >
                              <span style={{ fontWeight: "600" }}>
                                {isGerm ? DE.invite_all : EN.invite_all}
                              </span>
                            </div>
                            <div style={{ justifyContent: "center" }}>
                              <label className={props.isInternal ? "role-label-internal" : "role-label"}>
                                <input
                                  disabled
                                  checked={isAllMember}
                                  type="checkbox"
                                  style={{ backgroundColor: "red" }}
                                />
                                <span style={{ top: 0, right: 5 }} className={props.isInternal ? "checkmark-label-internal" : "checkmark-label"} />
                              </label>
                            </div>
                          </div>
                          :
                          <div style={{ textAlign: "center", paddingLeft: 20, paddingRight: 20, marginTop: 30 }}>
                            <span style={{ textAlign: "center", fontWeight: "500", color: "grey" }}>{!isGerm ? 'All available employees have already joined the room. Please invite missing employees to the company.' : "Alle Mitarbeiter/innen sind dem Raum bereits beigetreten. Fehlende Mitarbeiter/innen bitte zunächst zum Unternehmen einladen."}</span>
                          </div>
                        :
                        <></>
                    }
                    {
                      !props.isInternal ?
                        search ?
                          <div className="admin-content">
                            {allToSearch &&
                              allToSearch.map((u, i) => (
                                (u.id != user.id && props.Mems.findIndex(e => e.id == u.id) == -1) ?
                                  <div>
                                    <div key={i} className="admin-contact" onClick={() => {
                                      let ids = [...selectedIds]
                                      let ind = ids.findIndex(x => x == u.id)
                                      if (ind !== -1) {
                                        ids.splice(ind, 1)
                                      }
                                      else {
                                        ids.push(u.id)
                                      }
                                      setSelectedIds(ids)
                                      // let tmp = [...users]
                                      // let ids = []
                                      // tmp = tmp.map(e => {
                                      //   e.names = e.names.map(j => {
                                      //     return { ...j, addStatus: (u.id == j.id) ? !j.addStatus : j.addStatus }
                                      //   })
                                      //   let x = e.names.filter(xs => xs.addStatus)
                                      //   if (x?.length == e.names.length)
                                      //     ids = [...ids, { ...e }]
                                      //   return { ...e }
                                      // })
                                      // if (ids.length == tmp.length) {
                                      //   setIsAllMember(true)
                                      // }
                                      // else {
                                      //   setIsAllMember(false)
                                      // }
                                      // setUsers([...tmp])
                                    }} >
                                      <div className="row m-0">
                                        <div className="col-md-10 p-0">
                                          <div className="admin-control">
                                            {u.avatar ?
                                              <ImageWithPlaceholder
                                                width={50}
                                                height={50}
                                                className="news_profile"
                                                style={{ borderRadius: 6 }}
                                                src={BASE_URL + u.avatar}
                                                alt="G"
                                                fallbackSrc={(process.env.PUBLIC_URL + "/assets/img/head.svg")}
                                              />
                                              :
                                              <img
                                                width={50}
                                                height={50}
                                                className="news_profile"
                                                style={{ borderRadius: 6 }}
                                                src={(process.env.PUBLIC_URL + "/assets/img/head.svg")}
                                                alt="Generic placeholder"
                                              />
                                            }
                                            <h4>
                                              {u.fullName}
                                              {!props.isInternal ?
                                                <span>
                                                  {u.details
                                                    ?.split(",")
                                                    ?.map(
                                                      (itm, idx) =>
                                                        (idx ? ", " : "") +
                                                        convertBranchRole(itm, isGerm)
                                                    ) || u.details}
                                                </span>
                                                :
                                                <span>
                                                  {convertPosition(isGerm, _getPosition(props.cID, u.companies))}
                                                </span>
                                              }
                                            </h4>
                                          </div>
                                        </div>
                                        <div className="col-md-2" pr-0>
                                          <div className="custom_checkbox">
                                            <label className={props.isInternal ? "role-label-internal" : "role-label"}>
                                              <input
                                                checked={selectedIds.findIndex((e) => e == u.id) !== -1 ? true : false}
                                                disabled
                                                type="checkbox"
                                                value={u.id}
                                              />
                                              <span style={{ right: -10 }} className={props.isInternal ? "checkmark-label-internal" : "checkmark-label"}></span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  :
                                  <></>
                              ))}
                          </div>
                          :
                          comps && comps?.length > 0 ?
                            <div style={{ display: "flex", flexDirection: "column" }}>
                              <span style={{ fontWeight: "bold", marginTop: 10, marginBottom: 20, alignSelf: "center", fontSize: "17px" }}>
                                {isGerm ? DE.employees : EN.employees}
                              </span>
                              <div className="setting_accrd" style={{ marginRight: 0, padding: 0 }}>
                                <Accordion defaultActiveKey={'0'}>
                                  {comps.map((e, i) => {
                                    return (
                                      <Card>
                                        <Accordion.Toggle as={Card.Header} variant="link" eventKey={i.toString()}
                                          onClick={x => handleClass(i.toString(), e.id, e.employees ? true : false)}>{e.name}
                                          {
                                            (display === i.toString()) &&
                                            <BsChevronUp className="acr_up" />
                                          }
                                          {
                                            (display !== i.toString()) &&
                                            <BsChevronDown className="acr_down" />
                                          }
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={i.toString()}>
                                          <Card.Body>
                                            {!e.isGettingEmps ?
                                              e.employees && e.employees.length > 0 ?
                                                <div>
                                                  {e.employees.filter(u => (u.id != user.id && props.Mems.findIndex(e => e.id == u.id) == -1)).length > 0 ?
                                                    <div
                                                      style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}
                                                      onClick={() => {
                                                        let tmp = [...comps]
                                                        tmp[i].inviteAllSelcted = !tmp[i].inviteAllSelcted
                                                        if (tmp[i].inviteAllSelcted) {
                                                          let allIds = e.employees.map(e => {
                                                            return e.id
                                                          })
                                                          let txx = [...selectedIds]
                                                          txx = [...new Set([...txx, ...allIds])]
                                                          setSelectedIds([...txx])
                                                        }
                                                        else {
                                                          // Remove elements with IDs in idsToRemove from mainArray
                                                          let mainArray = [...selectedIds]
                                                          let allIds = e.employees.map(e => {
                                                            return e.id
                                                          })
                                                          mainArray = mainArray.filter(item => !allIds.includes(item));
                                                          mainArray = [...new Set([...mainArray])]
                                                          setSelectedIds([...mainArray])
                                                        }
                                                        setComps([...tmp])

                                                      }}
                                                      className="mx-1 my-3"
                                                    >
                                                      <div
                                                        style={{}}
                                                      >
                                                        <span style={{ fontWeight: "600" }}>
                                                          {isGerm ? DE.invite_all : EN.invite_all}
                                                        </span>
                                                      </div>
                                                      <div style={{ justifyContent: "center" }}>
                                                        <label className={props.isInternal ? "role-label-internal" : "role-label"}>
                                                          <input
                                                            disabled
                                                            checked={e.inviteAllSelcted}
                                                            type="checkbox"
                                                            style={{ backgroundColor: "red" }}
                                                          />
                                                          <span style={{ top: 0, right: 2 }} className={props.isInternal ? "checkmark-label-internal" : "checkmark-label"} />
                                                        </label>
                                                      </div>
                                                    </div>
                                                    :
                                                    <div style={{ display: "flex", flexDirection: "column" }}>
                                                      <span style={{ fontWeight: "500", color: "gray", marginTop: 10, marginBottom: 10, fontSize: "15px", textAlign: "center", alignSelf: "center" }}>
                                                        {!isGerm ? 'All Employees have already joined the room.' : "Alle Mitarbeiter/innen sind dem Projekt bereits beigetreten."}
                                                      </span>
                                                    </div>
                                                  }
                                                  <div>
                                                    {
                                                      e.employees.map((u, index) => {
                                                        return (
                                                          (u.id != user.id && props.Mems.findIndex(e => e.id == u.id) == -1) ?
                                                            <div key={i} className="admin-contact" onClick={() => {
                                                              let ids = [...selectedIds]
                                                              let ind = ids.findIndex(x => x == u.id)
                                                              if (ind !== -1) {
                                                                ids.splice(ind, 1)
                                                              }
                                                              else {
                                                                ids.push(u.id)
                                                              }
                                                              setSelectedIds(ids)
                                                              let tmp = [...comps]
                                                              for (let obj of tmp) {
                                                                let mainArray = [...ids]
                                                                let allIds = obj.employees?.map(e => {
                                                                  return e.id
                                                                })
                                                                mainArray = mainArray.filter(item => allIds?.includes(item));
                                                                mainArray = [...new Set([...mainArray])]
                                                                if (mainArray.length == obj.employees?.length) {
                                                                  obj.inviteAllSelcted = true
                                                                }
                                                                else {
                                                                  obj.inviteAllSelcted = false
                                                                }
                                                              }
                                                              setComps([...tmp])

                                                            }} >
                                                              <div className="row m-0">
                                                                <div className="col-md-10 p-0">
                                                                  <div className="admin-control">
                                                                    {u.avatar ?
                                                                      <ImageWithPlaceholder
                                                                        width={50}
                                                                        height={50}
                                                                        className="news_profile"
                                                                        style={{ borderRadius: 6 }}
                                                                        src={BASE_URL + u.avatar}
                                                                        alt="G"
                                                                        fallbackSrc={(process.env.PUBLIC_URL + "/assets/img/head.svg")}
                                                                      />
                                                                      :
                                                                      <img
                                                                        width={50}
                                                                        height={50}
                                                                        className="news_profile"
                                                                        style={{ borderRadius: 6 }}
                                                                        src={(process.env.PUBLIC_URL + "/assets/img/head.svg")}
                                                                        alt="Generic placeholder"
                                                                      />
                                                                    }
                                                                    <h4>
                                                                      {u.fullName}
                                                                      {!props.isInternal ?
                                                                        <span>
                                                                          {convertBranchRole(u.branch, isGerm, null, null, null, true)}
                                                                        </span>
                                                                        :
                                                                        <span>
                                                                          {convertPosition(isGerm, _getPosition(props.cID, u.companies))}
                                                                        </span>
                                                                      }
                                                                    </h4>
                                                                  </div>
                                                                </div>
                                                                <div className="col-md-2" pr-0>
                                                                  <div className="custom_checkbox">
                                                                    <label className={props.isInternal ? "role-label-internal" : "role-label"}>
                                                                      <input
                                                                        checked={selectedIds.findIndex((e) => e == u.id) !== -1 ? true : false}
                                                                        disabled
                                                                        type="checkbox"
                                                                        value={u.id}
                                                                      />
                                                                      <span style={{ right: -10 }} className={props.isInternal ? "checkmark-label-internal" : "checkmark-label"}></span>
                                                                    </label>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                            :
                                                            <></>
                                                        )
                                                      })
                                                    }
                                                  </div>
                                                </div>
                                                :
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                  <span style={{ fontWeight: "500", color: "gray", marginTop: 10, marginBottom: 10, fontSize: "15px", textAlign: "center", alignSelf: "center" }}>
                                                    {!isGerm ? 'All Employees have already joined the room.' : "Alle Mitarbeiter/innen sind dem Projekt bereits beigetreten."}
                                                  </span>
                                                </div>
                                              :
                                              e.isGettingEmps ?
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                  <div style={{ alignSelf: "center", marginTop: 10, marginBottom: 10 }}>
                                                    <Oval
                                                      height={30}
                                                      width={30}
                                                      color={KJUUP_COLORS.base_color}
                                                      wrapperStyle={{}}
                                                      wrapperClass=""
                                                      visible={true}
                                                      ariaLabel='oval-loading'
                                                      secondaryColor={'#f7f7f7'}
                                                      strokeWidth={5}
                                                      strokeWidthSecondary={5}
                                                    />
                                                  </div>
                                                </div>
                                                :
                                                <></>
                                            }
                                          </Card.Body>
                                        </Accordion.Collapse>
                                      </Card>
                                    )
                                  })}
                                </Accordion>
                              </div>
                            </div>
                            :
                            <></>
                        :
                        <></>
                    }
                    {
                      !props.isInternal && comps && comps?.length > 0 && !search ?
                        <div style={{ display: "flex", flexDirection: "column" }}>
                          <span style={{ fontWeight: "bold", marginTop: 20, marginBottom: 10, alignSelf: "center", fontSize: "17px" }}>
                            {isGerm ? DE.mems : EN.mems}
                          </span>
                        </div>
                        :
                        <></>
                    }
                    {!search ?
                      <div className="admin-content">
                        {users &&
                          users.map((user, index) => (
                            <div key={index} >
                              {user.names?.length > 0 && user.names.filter((u)=>{
                                return (u.id != user.id && props.Mems.findIndex(e => e.id == u.id) == -1)
                              }).length>0?
                                <h5 className="mt-2">{user.name}</h5>
                                :
                                <></>
                              }
                              {user.names && 
                                user.names.map((u, i) => (
                                  (u.id != user.id && props.Mems.findIndex(e => e.id == u.id) == -1) ?
                                    <div key={i} className="admin-contact" onClick={() => {
                                      let ids = [...selectedIds]
                                      let ind = ids.findIndex(x => x == u.id)
                                      if (ind !== -1) {
                                        ids.splice(ind, 1)
                                      }
                                      else {
                                        ids.push(u.id)
                                      }
                                      setSelectedIds(ids)
                                    }} >
                                      <div className="row m-0">
                                        <div className="col-md-10 p-0">
                                          <div className="admin-control">
                                            {u.avatar ?
                                              <ImageWithPlaceholder
                                                width={50}
                                                height={50}
                                                className="news_profile"
                                                style={{ borderRadius: 6 }}
                                                src={BASE_URL + u.avatar}
                                                alt="G"
                                                fallbackSrc={(process.env.PUBLIC_URL + "/assets/img/head.svg")}
                                              />
                                              :
                                              <img
                                                width={50}
                                                height={50}
                                                className="news_profile"
                                                style={{ borderRadius: 6 }}
                                                src={(process.env.PUBLIC_URL + "/assets/img/head.svg")}
                                                alt="Generic placeholder"
                                              />
                                            }
                                            <h4>
                                              {u.fullName}
                                              {!props.isInternal ?
                                                <span>
                                                  {u.details
                                                    ?.split(",")
                                                    ?.map(
                                                      (itm, idx) =>
                                                        (idx ? ", " : "") +
                                                        convertBranchRole(itm, isGerm)
                                                    ) || u.details}
                                                </span>
                                                :
                                                <span>
                                                  {convertPosition(isGerm, _getPosition(props.cID, u.companies))}
                                                </span>
                                              }
                                            </h4>
                                          </div>
                                        </div>
                                        <div className="col-md-2" pr-0>
                                          <div className="custom_checkbox">
                                            <label className={props.isInternal ? "role-label-internal" : "role-label"}>
                                              <input
                                                checked={selectedIds.findIndex((e) => e == u.id) !== -1 ? true : false}
                                                disabled
                                                type="checkbox"
                                                value={u.id}
                                              />
                                              <span style={{ right: -10 }} className={props.isInternal ? "checkmark-label-internal" : "checkmark-label"}></span>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    :
                                    <></>
                                ))}
                            </div>
                          ))}
                      </div>
                      :
                      props.isInternal ?
                        <div className="admin-content">
                          {allToSearch &&
                            allToSearch.map((u, i) => (
                              (u.id != user.id && props.Mems.findIndex(e => e.id == u.id) == -1) ?
                                <div>
                                  <div key={i} className="admin-contact" onClick={() => {
                                    let ids = [...selectedIds]
                                    let ind = ids.findIndex(x => x == u.id)
                                    if (ind !== -1) {
                                      ids.splice(ind, 1)
                                    }
                                    else {
                                      ids.push(u.id)
                                    }
                                    setSelectedIds(ids)
                                    // let tmp = [...users]
                                    // let ids = []
                                    // tmp = tmp.map(e => {
                                    //   e.names = e.names.map(j => {
                                    //     return { ...j, addStatus: (u.id == j.id) ? !j.addStatus : j.addStatus }
                                    //   })
                                    //   let x = e.names.filter(xs => xs.addStatus)
                                    //   if (x?.length == e.names.length)
                                    //     ids = [...ids, { ...e }]
                                    //   return { ...e }
                                    // })
                                    // if (ids.length == tmp.length) {
                                    //   setIsAllMember(true)
                                    // }
                                    // else {
                                    //   setIsAllMember(false)
                                    // }
                                    // setUsers([...tmp])
                                  }} >
                                    <div className="row m-0">
                                      <div className="col-md-10 p-0">
                                        <div className="admin-control">
                                          {u.avatar ?
                                            <ImageWithPlaceholder
                                              width={50}
                                              height={50}
                                              className="news_profile"
                                              style={{ borderRadius: 6 }}
                                              src={BASE_URL + u.avatar}
                                              alt="G"
                                              fallbackSrc={(process.env.PUBLIC_URL + "/assets/img/head.svg")}
                                            />
                                            :
                                            <img
                                              width={50}
                                              height={50}
                                              className="news_profile"
                                              style={{ borderRadius: 6 }}
                                              src={(process.env.PUBLIC_URL + "/assets/img/head.svg")}
                                              alt="Generic placeholder"
                                            />
                                          }
                                          <h4>
                                            {u.fullName}
                                            {!props.isInternal ?
                                              <span>
                                                {u.details
                                                  ?.split(",")
                                                  ?.map(
                                                    (itm, idx) =>
                                                      (idx ? ", " : "") +
                                                      convertBranchRole(itm, isGerm)
                                                  ) || u.details}
                                              </span>
                                              :
                                              <span>
                                                {convertPosition(isGerm, _getPosition(props.cID, u.companies))}
                                              </span>
                                            }
                                          </h4>
                                        </div>
                                      </div>
                                      <div className="col-md-2" pr-0>
                                        <div className="custom_checkbox">
                                          <label className={props.isInternal ? "role-label-internal" : "role-label"}>
                                            <input
                                              checked={selectedIds.findIndex((e) => e == u.id) !== -1 ? true : false}
                                              disabled
                                              type="checkbox"
                                              value={u.id}
                                            />
                                            <span style={{ right: -10 }} className={props.isInternal ? "checkmark-label-internal" : "checkmark-label"}></span>
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                <></>
                            ))}
                        </div>
                        :
                        <></>
                    }
                  </div>
                </div>
              </CustomScrollbars>
            </div>
          </div>
        </div>
      )}
      {inviteLink && (
        <InviteProjectUser
          project_id={props.project_id}
          handleClose={(e) => setInviteLink(false)}
        />
      )}
    </React.Fragment>
  );
}

export default AddProjectUser;
